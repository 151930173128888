import React, { FC, ReactNode } from 'react';
import styles from './RedesignedTable.module.scss';
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon';
import {useNavigate} from "react-router-dom";

interface HeaderItem {
    title?: string;
    iconId?: string;
    element?: React.ReactElement;
}

interface RedesignedTableProps {
    headers: HeaderItem[];
    rowsData: ReactNode[][];
    rowsHoverEffect?: boolean;
    hasCheckbox?: boolean;
    onRowClick?: (index: number) => string | undefined;
    allowVerticalOverflow?: boolean;
}

const RedesignedTable: FC<RedesignedTableProps> = ({ headers, rowsData, rowsHoverEffect, hasCheckbox, onRowClick, allowVerticalOverflow }) => {
    const navigate = useNavigate();

    const handleClick = (url: string | undefined, event: any): void => {
        if (event.button === 1 || event.ctrlKey || event.metaKey) return;
        url && navigate(url);
    };

    return (
        <div className={`${styles.tableWrapper} ${allowVerticalOverflow && styles.withVerticalScroll}`}>
            <table className={styles.table}>
                <thead className={styles.thead}>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index}>
                            {header.element ? (
                                header.element
                            ) : (
                                <div>
                                    <p>{header.title}</p>
                                    {header.iconId && (
                                        <SpriteIcon iconId={header.iconId} width={12} height={12}/>
                                    )}
                                </div>
                            )}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody className={`${styles.tbody} ${rowsHoverEffect && styles.withHover} ${hasCheckbox && styles.withCheckbox}`}>
                {rowsData.map((row, rowIndex) => (
                        <tr key={rowIndex} onClick={(e) => {
                            onRowClick && handleClick(onRowClick(rowIndex), e);
                        }}>
                            {row.map((cell, cellIndex) => {
                                return onRowClick
                                    ?   <td key={cellIndex}>
                                            <a href={onRowClick(rowIndex)}>
                                                {cell}
                                            </a>
                                        </td>
                                    : <td key={cellIndex}>{cell}</td>
                            })}
                        </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default RedesignedTable;