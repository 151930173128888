import React, {FC} from "react";
import styles from '../Modals.module.scss';
import bodyStyles from './CreateAnalyticsPresetModal.module.scss'
import {BaseModalProps} from "../../../types/BaseModalProps/BaseModalProps";
import Button, {ButtonStyles} from "../../UI/Button/Button";

interface CreateAnalyticsPresetModalProps extends BaseModalProps {}

const CreateAnalyticsPresetModal: FC<CreateAnalyticsPresetModalProps> = ({ onClose }) => {
    return (
        <div className={styles.modalInner}>
            <header className={styles.header}>
                Create Preset
            </header>

            <footer className={styles.footer}>
                <Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
                    Cancel
                </Button>

                <Button style={ButtonStyles.PRIMARY} onClick={() => {}}>
                    Create
                </Button>
            </footer>
        </div>
    )
};

export default CreateAnalyticsPresetModal;