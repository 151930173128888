import {BaseModalProps} from "../../../types/BaseModalProps/BaseModalProps";
import React, {FC, useState} from "react";
import styles from "../Modals.module.scss";
import bodyStyles from './SetSubscriptionExpirationModal.module.scss'
import Button, {ButtonStyles} from "../../UI/Button/Button";
import Radio from "../../UI/Radio/Radio";
import DatePicker from "../../UI/DatePicker/DatePicker";
import {useAppContext} from "../../../hooks/useAppContext";
import {useAPI} from "../../../hooks/useAPI";
import {BoughtSubscriptionsService} from "../../../API/BoughtSubscriptionsService";

interface SetSubscriptionExpirationModalProps extends BaseModalProps {
    onConfirm: () => void;
    subscriptionId: string;
}

const SetSubscriptionExpirationModal: FC<SetSubscriptionExpirationModalProps> = ({ onClose, onConfirm, subscriptionId }) => {
    const [chosenVariant, setChosenVariant] = useState('0');
    const [endDate, setEndDate] = useState<Date | null>(null);

    const { shop } = useAppContext();

    const currentDateObj = new Date();
    const currentDate = currentDateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });

    const [, , expireSubscription] = useAPI(
        () => BoughtSubscriptionsService.expireSubscription(
            shop?.id || '',
            subscriptionId,
            endDate ? endDate.toISOString() : null,
        ),
        false,
        undefined,
        {
            onSuccess: onConfirm
        }
    );

    return (
        <div className={styles.modalInner}>
            <header className={styles.header}>
                <h1>Set Expiration Date</h1>
            </header>

            <div className={bodyStyles.body}>
                <div className={bodyStyles.item} onClick={() => {
                    setChosenVariant('0')
                    setEndDate(null);
                }}>
                    <Radio id={'indefinite'} value={'0'} setValue={setChosenVariant} checked={chosenVariant === '0'}/>
                    <div className={bodyStyles.ItemText}>
                        <span>Expire now</span>
                        <p>Payments and access ends immediately {' '} {currentDate}</p>
                    </div>
                </div>

                <div className={bodyStyles.item} onClick={() => {
                    setChosenVariant('1')
                }}>
                    <Radio id={'custom'} value={'1'} setValue={setChosenVariant} checked={chosenVariant === '1'}/>
                    <div className={bodyStyles.ItemText}>
                        <span>On a custom date</span>
                        <p>Select a specific date to expire</p>
                    </div>
                    <div className={bodyStyles.ItemDate}>
                        <DatePicker
                            minDate={new Date()}
                            width={100}
                            date={endDate ?? new Date()}
                            setDate={setEndDate}
                            disabled={chosenVariant !== '1'}
                        />
                    </div>
                </div>
            </div>

            <footer className={styles.footer}>
                <div className={styles.buttons} style={{
                    marginLeft: 'auto',
                    width: 'fit-content'
                }}>
                    <Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        width={150}
                        style={ButtonStyles.PRIMARY}
                        onClick={() => {
                            expireSubscription();
                        }}
                    >
                        {chosenVariant === '0' ? 'Expire' : 'Set Expiration Date'}
                    </Button>
                </div>
            </footer>
        </div>
    )
};

export default SetSubscriptionExpirationModal;