import { privateAPI } from './API'
import { OrderStatus } from '../types/OrderStatus/OrderStatus'

export const OrdersService = {
	getAll: async (
		shopId: string,
		statusFilter: OrderStatus | null,
		searchString?: string,
		PageNumber?: number,
		PageSize?: number,
		subscriptionIdFilter?: string,
	) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/orders`, {
			params: {
				statusFilter,
				searchString,
				PageNumber,
				PageSize,
				subscriptionIdFilter
			},
		})
	},
	getOne: async (shopId: string, id: string | undefined) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/orders/${id}`)
	},
	replacePartOrder: async (
		shopId: string,
		orderId: string | undefined,
		partOrderId: number,
		data: {
			quantity: number
		}
	) => {
		return await privateAPI.post(
			`/v1/dash/shops/${shopId}/orders/${orderId}/part-orders/${partOrderId}/replace`,
			data
		)
	},
	resendOrder: async (
		shopId: string,
		orderId: string | undefined,
		data: {
			email: string
		}
	) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/orders/${orderId}/resend`, data)
	},
	refundOrder: async (shopId: string, orderId: string | undefined) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/orders/${orderId}/refund`)
	},
}
