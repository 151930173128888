import { privateAPI } from './API'
import {TelegramBot} from "../types/TelegramBot/TelegramBot";

export const TelegramBotService = {
    getBots: async (
        shopId: string,
        searchString?: string,
        PageNumber?: number,
        PageSize?: number,
    ) => {
        return await privateAPI.get(`/v1/dash/shops/${shopId}/telegram-bots`, {
            params: {
                searchString,
                PageNumber,
                PageSize,
            },
        })
    },
    createBot: async (
        shopId: string,
        data: TelegramBot
    ) => {
        return await privateAPI.post(`/v1/dash/shops/${shopId}/telegram-bots`, data)
    },
    deleteBot: async (
        shopId: string,
        botId: string
    ) => {
        return await privateAPI.delete(`/v1/dash/shops/${shopId}/telegram-bots/${botId}`)
    },
    editBot: async (
        shopId: string,
        botId: string,
        data: TelegramBot
    ) => {
        return await privateAPI.put(`/v1/dash/shops/${shopId}/telegram-bots/${botId}`, data)
    }
}