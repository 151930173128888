import {FC} from "react";
import {ConfigProvider} from "antd";

export const AntDesignSelectorConfig:FC<{children: any}> = ({ children }) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Select: {
                        optionSelectedBg: 'var(--light_gray)',
                        selectorBg: 'var(--light_color)'
                    },
                },
                token: {
                    colorText: 'var(--dark_color)',
                    colorBgElevated: 'var(--light_color)',
                    colorBorder: 'var(--border_color_secondary)',
                },
            }}
        >
            {children}
        </ConfigProvider>
    )
};