import React, { FC, useRef, useState } from 'react'
import styles from './Dropdown.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import { he } from 'date-fns/locale'

interface DropdownProps {
	options: {
		icon?: {
			id: string
			width: number
			height: number
		}
		text?: string
		onClick?: () => void
		isHidden?: boolean
	}[]
	title?: string
	style?: 'header' | 'headerNotActive'
	height?: number
	width?: number
	right?: number
	top?: number | string
	bottom?: number | string
	iconId?: string
	iconWidth?: number
	iconHeight?: number
	customFaceStyle?: string
	customDropdownMenuStyle?: string
	customDropdownItemStyle?: string
}

const Dropdown: FC<DropdownProps> = ({ options, title, style, height, width, right, bottom, iconId, iconHeight, iconWidth, customFaceStyle, customDropdownMenuStyle, customDropdownItemStyle, top }) => {
	const [isActive, setIsActive] = useState(false)
	const dropdownRef = useRef<HTMLDivElement>(null)
	useOutsideClick(dropdownRef, () => setIsActive(false), !isActive)

	return (
		<div
			className={`${styles.dropdown} ${isActive && styles.active} ${style && styles[style]}`}
			ref={dropdownRef}
		>
			<div className={customFaceStyle ?? styles.face} onClick={() => setIsActive(!isActive)}>
				{title} <SpriteIcon iconId={iconId ? iconId : 'arrowDown'} width={iconWidth ?? 10}
									height={iconHeight ?? 4}/>
			</div>

			<div className={`${styles.dropdownMenu} ${customDropdownMenuStyle}`} style={{
				width: width && `${width}px`,
				height: height && `${height}px`,
				right: right && `${right}px`,
				top: top ?? '',
				bottom: bottom ?? '',
			}}>
				{options.map(option => (
					<div className={styles.dropdownItemWrapper} style={{
						display: option.isHidden ? 'none' : '',
					}}>
						<div className={`${styles.dropdownItem} ${customDropdownItemStyle}`}
							 onClick={option.onClick}>
							{option.text}
							{option.icon && (
								<div className={styles.icon}>
									<SpriteIcon
										iconId={option.icon.id}
										width={option.icon.width}
										height={option.icon.height}
									/>
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default Dropdown
