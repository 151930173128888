import React, {FC} from "react";
import styles from "./GraphWrapper.module.scss";
import FeedbackActionMenu from "../FeedbackActionMenu/FeedbackActionMenu";

interface GraphWrapperProps {
    title: string;
    children: any;
}

export const GraphWrapper: FC<GraphWrapperProps> = ({title, children}) => {
    return (
        <div className={styles.analyticsItem}>
            <header className={styles.header}>
                <div className={styles.head}>
                    <h1>{title}</h1>
                </div>
                <FeedbackActionMenu/>
            </header>
            {children}
        </div>
    )
};