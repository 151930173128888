import React, {FC} from "react";
import {BaseModalProps} from "../../../types/BaseModalProps/BaseModalProps";
import styles from "../Modals.module.scss";
import bodyStyles from './CreateTelegramBotModal.module.scss'
import Button, {ButtonsIconAlign, ButtonStyles} from "../../UI/Button/Button";
import {SpriteIcon} from "../../UI/SpriteIcon/SpriteIcon";

interface InputProps {
    title: string
    placeholder: string
    value: string
    setValue: (value: string) => void
}

const ModalInput: FC<InputProps> = ({ value, setValue, title, placeholder }) => {
    return (
        <div className={bodyStyles.modalInput}>
            <p>{title}</p>
            <input type="text" placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)}/>
        </div>
    )
}

interface CreateTelegramBotModalProps extends BaseModalProps {
    title: string
    buttonText: string
    buttonIconId: string
    onCreateBot: () => void
    createBotLoading: boolean
    token: string;
    setToken: (token: string) => void;
    botName: string;
    setBotName: (name: string) => void;
    supportUsername: string;
    setSupportUsername: (username: string) => void;
}

const CreateTelegramBotModal: FC<CreateTelegramBotModalProps> = ({
     onClose,
     onCreateBot,
     title,
     buttonText,
     buttonIconId,
     token,
     setToken,
     botName,
     setBotName,
     supportUsername,
     setSupportUsername,
    createBotLoading
 }) => {
    return (
        <div className={styles.modalInner}>
            <header className={styles.header}>
                <h1>{title}</h1>
            </header>

            <div className={bodyStyles.body}>
                <div className={bodyStyles.reminder}>
                    <SpriteIcon iconId={'information'} width={16} height={16}/>
                    <span>
                    We strongly recommend reviewing this <a
                        href="https://support.billgang.com/en/articles/9858771-how-do-i-create-a-custom-telegram-bot-and-start-accepting-telegram-stars-as-a-payment-type" target="_blank">support article</a> to get started with custom Telegram bots.
                </span>
                </div>

                <div className={bodyStyles.token}>
                    <ModalInput title={'Bot Token'} placeholder={'Secure Bot Token'} value={token}
                                setValue={(value) => {
                                    setToken(value);
                                }}/>
                </div>

                <div className={bodyStyles.name}>
                    <ModalInput title={'Bot Name'} placeholder={'i.e Payments'} value={botName} setValue={(value) => {
                        setBotName(value);
                    }}/>
                </div>

                <div className={bodyStyles.supportUsername}>
                    <ModalInput title={'Support Username'} placeholder={'@username'} value={supportUsername}
                                setValue={(value) => {
                                    setSupportUsername(value);
                                }}/>
                    <span>
                        Enter your telegram account which will be shown to customers if they ask for support via /support command.
                    </span>
                </div>
            </div>

            <footer className={styles.footer}>
                <div className={styles.buttons}>
                    <Button style={ButtonStyles.BORDERED} disableShadow={true} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        style={ButtonStyles.BORDERED}
                        icon={
                            {
                                id: buttonIconId,
                                width: 15,
                                height: 15,
                                align: ButtonsIconAlign.LEFT,
                            }
                        } size={'large'} onClick={() => {
                        if (token && botName && supportUsername) {
                            onCreateBot();
                        }
                        }}
                        isLoading={createBotLoading}
                    >
                        {buttonText}
                    </Button>
                </div>
            </footer>
        </div>
    )
}

export default CreateTelegramBotModal