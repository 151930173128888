import {useEffect, useRef} from "react";

export const useAfterMountEffect = (effect: any, deps: any[]) => {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            return effect();
        }
    }, deps);
};
