import { useState, useRef, useEffect } from "react";
import { format } from "date-fns";

interface DateRange {
    fromDate: Date | null;
    toDate: Date | null;
}

interface HandledData {
    count: number;
    date: string;
    compareCount?: number;
}

export const useHandledData = (data: any[], dateRange: DateRange, isCompare?: boolean) => {
    const [handledData, setHandledData] = useState<HandledData[]>([]);
    const dateRangeFrom = dateRange.fromDate ?? new Date();
    const dateRangeTo = dateRange.toDate?? new Date();
    const totalValueRef = useRef(0);
    const [focusValue, setFocusValue] = useState<string | number>(totalValueRef.current);
    const oneDayInMs = 86400000;
    const dateRangeDifference = dateRangeTo.getTime() - dateRangeFrom.getTime();
    const dateRangeDifferenceDays = Math.floor(dateRangeDifference / oneDayInMs);

    const handleData = () => {
        const newData: any[] = [];

        const dateFormat: string =
            dateRangeDifference === oneDayInMs
                ? 'HH:mm'
                : dateRangeDifference > 90 * oneDayInMs
                    ? 'MMM yyyy'
                    : 'MMM dd'

        const incrementTime: number =
            dateRangeDifference === oneDayInMs
                ? 60 * 60 * 1000
                : dateRangeDifference > 90 * oneDayInMs
                    ? 30 * oneDayInMs
                    : oneDayInMs

        const datePoints: number =
            dateRangeDifferenceDays > 30
                ? Math.ceil(dateRangeDifferenceDays / 30)
                : dateRangeDifferenceDays;

        let date = new Date(dateRangeFrom);
        const endDate1 = new Date(dateRangeFrom);

        for (let i = 0; i < datePoints; i++) {
            const endDate = new Date(
                endDate1.setTime(
                    endDate1.getTime() +
                    (i === 3 && incrementTime === oneDayInMs * 365
                        ? incrementTime + 24 * 60 * 60 * 1000
                        : incrementTime)
                )
            );

            const values = data
                .filter(item => {
                    const itemDate = new Date(item.date);
                    return itemDate.getTime() >= date.getTime() && itemDate.getTime() <= endDate.getTime();
                })
                .map(item => item.count);

            if (isCompare) {
                newData.push({
                    date: format(date, dateFormat),
                    compareCount: values.reduce((a, b) => a + b, 0),
                });
            } else {
                newData.push({
                    date: format(date, dateFormat),
                    count: values.reduce((a, b) => a + b, 0),
                });
            }

            date = endDate;
        }
        setHandledData(newData);
    };

    useEffect(() => {
        if (handledData) totalValueRef.current = handledData.map(data => data.count).reduce((a, b) => a + b, 0);
    }, [handledData]);

    useEffect(() => {
        handleData();
    }, [data]);

    return { handledData, totalValueRef, focusValue, setFocusValue };
};