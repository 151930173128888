import { FC } from 'react'
import styles from './SubscriptionsPageActions.module.scss'
import Button, { ButtonsIconAlign } from '../../../components/UI/Button/Button'
import SearchInput, { SearchInputStyles } from '../../../components/UI/SearchInput/SearchInput'
import Dropdown from '../../../components/UI/Dropdown/Dropdown'
import {SubscriptionStatus} from "../../../types/SubscriptionStatus/SubscriptionStatus";

interface SubscriptionsPageActionsProps {
	searchValue: string;
	setSearchValue: (value: string) => void;
	filterValue: string;
	setFilterValue: (value: string) => void;
}

const SubscriptionsPageActions: FC<SubscriptionsPageActionsProps> = ({
	 searchValue,
	 setSearchValue,
	 filterValue,
	 setFilterValue
		}) => {
	return <div className={styles.subscriptionsPageActions}>
		<div className={styles.filters}>
			<SearchInput value={searchValue} setValue={(value) => {setSearchValue(value)}} style={
				SearchInputStyles.bordered
			} placeholder={'Search for subscriptions...'} customClass={styles.filtersSearch} />

			<Dropdown
				options={
					[
						{
							text: 'All Subscriptions',
							onClick: () => {setFilterValue('All Subscriptions')},
						},
						...Object.keys(SubscriptionStatus).map((key) => {
							return {
								// @ts-ignore
								text: SubscriptionStatus[key],
								onClick: () => {setFilterValue(key)},
							}
						})
					]
				}
		  		title={
					// 	@ts-ignore
					SubscriptionStatus[filterValue] ?? filterValue
				}
			  	height={200}
				width={150}
			/>

		</div>
		<div className={styles.buttons}>
			<Button icon={
				{
					id: 'export',
					width: 20,
					height: 20,
					align: ButtonsIconAlign.LEFT,
				}
			} size={'small'}>
			Export
			</Button>

			<Button icon={
				{
					id: 'gear',
					width: 20,
					height: 20,
					align: ButtonsIconAlign.LEFT,
				}
			} size={'small'}>
				Edit
			</Button>
		</div>
	</div>
}

export default SubscriptionsPageActions