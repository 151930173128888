export enum SubscriptionStatus {
	ACTIVE = 'Active',
	CANCELLED = 'Cancelled',
	EXPIRED = 'Expired',
	TRIAL = 'Trial',
	AWAITING_PAYMENT = 'Awaiting Payment',
	PAST_DUE = 'Past due',
	CHANGED_GATEWAY = 'Changed gateway',
	REPLACED = 'Replaced'
}
