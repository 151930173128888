export enum OrderStatus {
	NEW = 'NEW',
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
	CANCELLED = 'CANCELLED',
	EXPIRED = 'EXPIRED',
	PAID = 'PAID',
	FULL_DELIVERY_FAILURE = 'FULL_DELIVERY_FAILURE',
	PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED',
	REFUNDED = 'REFUNDED',
}
