import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { format } from 'date-fns'
import { CustomPlugin } from '../../types/Plugin/CustomPlugin'
import PluginApprovalStatusBadge from '../UI/StatusBadges/PluginApprovalStatusBadge'
import { NavLink, useNavigate } from 'react-router-dom'

interface PluginsTableProps {
	plugins: CustomPlugin[]
	onDeleteClick: (id: number) => void
	onEditClick: (id: number) => void
}

interface PluginsTableBodyItemProps {
	plugin: CustomPlugin
	onDeleteClick: (id: number) => void
	onEditClick: (id: number) => void
}

const PluginsTableBodyItem: FC<PluginsTableBodyItemProps> = ({
	onDeleteClick,
	plugin,
	onEditClick,
}) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const tableBodyRef = useRef<HTMLAnchorElement>(null)
	const navigate = useNavigate()

	const actionMenuButtons = [
		{
			onClick: () => onEditClick(plugin.id),
			icon: {
				id: 'pen',
				width: 13,
				height: 13,
			},
			title: 'Edit Plugin',
		},
		{
			onClick: () => onDeleteClick(plugin.id),
			icon: {
				id: 'trashBin',
				width: 13,
				height: 13,
			},
			title: 'Delete Plugin',
		},
		{
			onClick: () => navigate(`/integrations/custom-plugins/${plugin.id}`),
			icon: {
				id: 'book',
				width: 13,
				height: 13,
			},
			title: 'View Versions',
		},
	]

	return (
		<NavLink
			className={`${styles.trWrapper} ${isActionMenuActive && styles.actionMenuActive}`}
			style={{
				paddingBottom: isActionMenuActive ? +actionMenuButtons.length * 50 : 5,
			}}
			to={`/integrations/custom-plugins/${plugin.id}`}
			ref={tableBodyRef}
		>
			<tr>
				<td className={styles.large}>
					<div className={styles.itemInfo}>
						<div className={styles.itemImage}>
							<img
								src={`https://imagedelivery.net/${plugin.logoImage.cfId}/productCard`}
								alt={plugin.name}
							/>
						</div>
						<div>
							<p>{plugin.displayName}</p>
							<span>{plugin.description}</span>
						</div>
					</div>
				</td>
				<td>
					<PluginApprovalStatusBadge status={plugin.approvalStatus} />
				</td>
				<td>{plugin.visibility}</td>
				<td>{plugin.paymentMethods.length}</td>
				<td>
					<p>{format(new Date(plugin.updatedAt), 'MMM dd, yyyy')}</p>
					<span>{format(new Date(plugin.updatedAt), 'hh:mm aa z')}</span>
				</td>
				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionMenuButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</NavLink>
	)
}

const PluginsTable: FC<PluginsTableProps> = ({ plugins, onDeleteClick, onEditClick }) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>Plugin Information</th>
					<th>status</th>
					<th>Visibility</th>
					<th>Methods</th>
					<th>Last Updated</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{plugins.map(category => (
					<PluginsTableBodyItem
						plugin={category}
						onDeleteClick={onDeleteClick}
						onEditClick={onEditClick}
					/>
				))}
			</tbody>
		</table>
	)
}

export default PluginsTable
