import React, {FC} from "react";
import styles from './AdvancedAnalyticsReportPageLoading.module.scss';
import Spinner from "../../../components/UI/Spinner/Spinner";
import {SpriteIcon} from "../../../components/UI/SpriteIcon/SpriteIcon";

export const AdvancedAnalyticsReportPageLoading: FC = () => {
    return (
        <div className={`${styles.loading}`}>
            <div className={styles.spinner}>
                <Spinner circleColor={'var(--primary_color)'} />
            </div>
            <h1>Generating a report...</h1>
            <p className={styles.description} dangerouslySetInnerHTML={{__html: 'It usually takes a few seconds. Please wait'}}></p>
            <div className={styles.advice}>
                <SpriteIcon iconId={'information'} width={13} height={13} />
                <span>We recommend that you use the Create Preset for quick access.</span>
            </div>
        </div>
    )
};