import {FC} from "react";
import styles from './UserDefaultAvatar.module.scss';

interface UserDefaultAvatarProps {
    text: string;
    width?: number;
    height?: number;
    backgroundColor?: string;
}

const UserDefaultAvatar: FC<UserDefaultAvatarProps> = ({ text, width, height, backgroundColor }) => {
    let letters: string;
    if (text.includes('@')) {
        const splitText = text[0] === '@' ? text.split('@')[1] : text.split('@')[0];
        letters = splitText[0] + splitText[splitText.length - 1];
    } else {
        letters = text[0] + text[text.length - 1];
    }

    return (
        <div className={styles.avatar}
             style={{
                 width: width && '32px',
                 height: height && '32px',
                 backgroundColor: backgroundColor ?? '',
             }}
        >
            {letters.toUpperCase()}
        </div>
    );
};

export default UserDefaultAvatar;