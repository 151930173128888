import { FC } from 'react'
import styles from './TelegramBotsPageActions.module.scss'
import Button, { ButtonsIconAlign } from '../../../components/UI/Button/Button'
import SearchInput, { SearchInputStyles } from '../../../components/UI/SearchInput/SearchInput'

interface TelegramBotsPageActionsProps {
    searchValue: string;
    setSearchValue: (value: string) => void;
    onCreateBot: () => void;
}

const TelegramBotsPageActions: FC<TelegramBotsPageActionsProps> = ({
     searchValue,
     setSearchValue,
    onCreateBot
 }) => {
    return <div className={styles.subscriptionsPageActions}>
        <div className={styles.filters}>
            <SearchInput value={searchValue} setValue={(value) => {setSearchValue(value)}} style={
                SearchInputStyles.bordered
            } placeholder={'Search for bots...'} customClass={styles.filtersSearch} />
        </div>

        <div className={styles.buttons}>
            <Button icon={
                {
                    id: 'addNew',
                    width: 15,
                    height: 15,
                    align: ButtonsIconAlign.LEFT,
                }
            } size={'large'} onClick={onCreateBot}>
                New Telegram Bot
            </Button>

            <Button icon={
                {
                    id: 'gear',
                    width: 20,
                    height: 20,
                    align: ButtonsIconAlign.LEFT,
                }
            } size={'small'}>
            </Button>
        </div>
    </div>
}

export default TelegramBotsPageActions