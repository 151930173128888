import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import styles from '../Settings.module.scss'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../../components/UI/Button/Button'
import { useAppContext } from '../../../hooks/useAppContext'
import SearchInput, { SearchInputStyles } from '../../../components/UI/SearchInput/SearchInput'
import Pagination from '../../../components/UI/Pagination/Pagination'
import AuditLogTable from '../../../components/Tables/AuditLogTable'
import Modal from '../../../components/Modals/Modal'
import TeamMemberEventDetailsModal from '../../../components/Modals/TeamMemberEventDetailsModal/TeamMemberEventDetailsModal'
import { closeModal } from '../../../helpers/closeModal'
import { useAPI } from '../../../hooks/useAPI'
import { AuditLogsService } from '../../../API/AuditLogsService'
import { AuditLog } from '../../../types/AuditLog/AuditLog'
import Spinner from '../../../components/UI/Spinner/Spinner'
import { useDebounce } from '../../../hooks/useDebounce'
import TablePagination from "../../../components/TablePagination/TablePagination";
import {usePagination} from "../../../hooks/usePagination";

const SettingsAuditLogPage = () => {
	const [searchValue, setSearchValue] = useState('')
	const [isViewDetailsModalActive, setIsViewDetailsModalActive] = useState(false)
	const [isViewDetailsModalVisible, setIsViewDetailsModalVisible] = useState(false)
	const [currentAuditLog, setCurrentAuditLog] = useState<AuditLog | null>(null)

	const {
		totalItems,
		currentPage,
		pageSize,
		setPageSize,
		setCurrentPage,
		updateTotalItems
	} = usePagination();
	const { shop } = useAppContext()

	const [isGetAuditLogsLoading, auditLogs, getAuditLogs] = useAPI<AuditLog[]>(
		() => AuditLogsService.getAll(shop?.id || '', searchValue, currentPage + 1, pageSize),
		false,
		undefined,
		{
			onSuccess: response => {
				updateTotalItems(response.headers['x-pagination-total']);
			},
		}
	)

	useDebounce(searchValue, getAuditLogs, 400)

	useEffect(() => {
		shop && getAuditLogs();
	}, [shop, currentPage, pageSize, searchValue])

	return (
		<PageWrapper>
			{isViewDetailsModalActive && (
				<Modal
					closeModal={() => closeModal(setIsViewDetailsModalActive, setIsViewDetailsModalVisible)}
					visible={isViewDetailsModalVisible}
					setVisible={setIsViewDetailsModalVisible}
				>
					<TeamMemberEventDetailsModal
						currentAuditLog={currentAuditLog}
						onClose={() => closeModal(setIsViewDetailsModalActive, setIsViewDetailsModalVisible)}
					/>
				</Modal>
			)}

			<div className={styles.settingsPageInnerBody}>
				<header className={styles.pageInnerHeader}>
					<SearchInput
						style={SearchInputStyles.secondary}
						value={searchValue}
						setValue={setSearchValue}
						placeholder={'Search for event or team member'}
					/>
				</header>

				<header className={styles.settingSectionHeader}>
					<h1 className={styles.settingSectionTitle}>Audit Log</h1>
				</header>

				{isGetAuditLogsLoading
					? <Spinner/>
					: auditLogs && (
					<>
						<AuditLogTable
							onViewDetailsClick={auditLog => {
								setIsViewDetailsModalActive(true)
								setCurrentAuditLog(auditLog)
							}}
							auditLogs={auditLogs}
						/>

						<TablePagination
							totalItems={totalItems}
							pageSize={pageSize}
							setPageSize={setPageSize}
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
						/>
					</>
				)
				}
			</div>
		</PageWrapper>
	)
}

export default SettingsAuditLogPage
