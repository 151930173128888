import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink, useNavigate } from 'react-router-dom'
import Checkbox from '../UI/Checkbox/Checkbox'
import ProductTypeBadge from '../UI/ProductTypeBadge/ProductTypeBadge'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { Category } from '../../types/Category/Category'
import { format } from 'date-fns'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'
import RedesignedTable from "../RedesignedTable/RedesignedTable";
import {MenuProps} from "antd";
import DropdownMenuItem from "../UI/DropdownMenuItem/DropdownMenuItem";
import TableDropdown from "../UI/TableDropdown/TableDropdown";

interface CategoriesTableProps {
	categories: Category[]
	onDeleteClick: (id: number) => void
	isCategoriesDeleteAvailable: boolean
	isCategoriesModifyAvailable: boolean
}

const CategoriesTable: FC<CategoriesTableProps> = ({
	categories,
	onDeleteClick,
	isCategoriesModifyAvailable,
	isCategoriesDeleteAvailable,
}) => {
	const navigate = useNavigate();

	const dropdownMenu = (category: Category): any => {
		const items: MenuProps['items'] = [
			{
				label: <DropdownMenuItem
					text={'Edit category'}
					iconId={'pen'}
				/>,
				key: '0',
				onClick: () => navigate(`/products/categories/edit/${category.id}`),
				disabled: !isCategoriesModifyAvailable
			},
			{
				label: <DropdownMenuItem
					text={'Delete category'}
					iconId={'trashBin'}
				/>,
				key: '1',
				onClick: () => onDeleteClick(category.id),
				disabled: !isCategoriesDeleteAvailable
			},
		];

		return { items };
	};

	const headers = [
		{ title: 'Category' },
		{ title: 'Products' },
		{ title: 'Last Updated' },
		{ title: '' },
	];

	const rowsData = categories.map((category) => [
		<div className={styles.itemInfo}>
			<div>
				<p>{category.name}</p>
				<span>ID: {category.id}</span>
			</div>
		</div>,

		<>
			{category.listingsCount}
		</>,

		<>
			<p>{format(new Date(category.updatedAt), 'MMM dd, yyyy')}</p>
			<span>{format(new Date(category.updatedAt), 'hh:mm aa z')}</span>
		</>,

		<TableDropdown menuItems={dropdownMenu(category)} />
	]);

	return <RedesignedTable headers={headers} rowsData={rowsData} rowsHoverEffect />;
}

export default CategoriesTable
