import React, { FC, useRef, useState } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import { NavLink, useNavigate } from 'react-router-dom'
import Checkbox from '../UI/Checkbox/Checkbox'
import ProductTypeBadge from '../UI/ProductTypeBadge/ProductTypeBadge'
import { Coupon } from '../../types/Coupon/Coupon'
import OrangeBadge from '../UI/OrangeBadge/OrangeBadge'
import { format } from 'date-fns'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import CouponTypeBadge from '../UI/CouponTypeBadge/CouponTypeBadge'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'

interface CouponsTableProps {
	coupons: Coupon[]
	onDeleteClick: (value: number) => void
	isCouponsModifyAvailable: boolean
	isCouponsDeleteAvailable: boolean
}

interface CouponsTableBodyProps {
	coupon: Coupon
	onDeleteClick: (value: number) => void
	isCouponsModifyAvailable: boolean
	isCouponsDeleteAvailable: boolean
}

const CouponsTableBody: FC<CouponsTableBodyProps> = ({
	coupon,
	onDeleteClick,
	isCouponsModifyAvailable,
	isCouponsDeleteAvailable,
}) => {
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const tableBodyRef = useRef<HTMLAnchorElement>(null)
	const navigate = useNavigate()

	const actionMenuButtons: ActionMenuOption[] = []

	isCouponsModifyAvailable &&
		actionMenuButtons.push({
			onClick: () => navigate(`/marketing/coupons/edit/${coupon.id}`),
			icon: {
				id: 'pen',
				width: 14,
				height: 14,
			},
			title: 'Edit coupon',
		})

	isCouponsDeleteAvailable &&
		actionMenuButtons.push({
			onClick: () => onDeleteClick(coupon.id),
			icon: {
				id: 'trashBin',
				width: 14,
				height: 14,
			},
			title: 'Delete coupon',
		})

	return (
		<NavLink
			className={styles.trWrapper}
			to={`/marketing/coupons/edit/${coupon.id}`}
			style={{
				paddingBottom: isActionMenuActive ? +actionMenuButtons.length * 50 : 5,
			}}
			ref={tableBodyRef}
		>
			<tr>
				<td className={styles.large}>
					<div className={styles.itemInfo}>
						<div>
							<p>{coupon.name}</p>
							<span>ID: {coupon.id}</span>
						</div>
					</div>
				</td>
				<td>∞</td>
				<td>
					<CouponTypeBadge isFixed={coupon.isFixed} />
				</td>
				<td>{coupon.uses}</td>
				<td>{coupon.discount}</td>
				<td>
					<p>{format(new Date(coupon.updatedAt), 'MMM dd, yyyy')}</p>
					<span>{format(new Date(coupon.updatedAt), 'hh:mm aa z')}</span>
				</td>
				<td className={styles.small}>
					<ActionMenu
						actionButtons={actionMenuButtons}
						isActive={isActionMenuActive}
						setIsActive={setIsActionMenuActive}
					/>
				</td>
			</tr>
		</NavLink>
	)
}

const CouponsTable: FC<CouponsTableProps> = ({
	coupons,
	onDeleteClick,
	isCouponsModifyAvailable,
	isCouponsDeleteAvailable,
}) => {
	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				<tr>
					<th className={styles.large}>Coupon</th>
					<th>Products</th>
					<th>Type</th>
					<th>Uses</th>
					<th>Discount</th>
					<th>Last Updated</th>
					<th className={styles.small}></th>
				</tr>
			</thead>
			<tbody className={styles.tbody}>
				{coupons.map(coupon => (
					<CouponsTableBody
						isCouponsDeleteAvailable={isCouponsDeleteAvailable}
						isCouponsModifyAvailable={isCouponsModifyAvailable}
						coupon={coupon}
						key={coupon.id}
						onDeleteClick={onDeleteClick}
					/>
				))}
			</tbody>
		</table>
	)
}

export default CouponsTable
