import React, {useEffect, useRef, useState} from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import styles from '../Settings.module.scss'
import paymentsStyles from './SettingsPaymentsPage.module.scss'
import Modal from '../../../components/Modals/Modal'
import {closeModal} from '../../../helpers/closeModal'
import {useAPI} from '../../../hooks/useAPI'
import {PaymentsSettingsService} from '../../../API/PaymentsSettingsService'
import {useAppContext} from '../../../hooks/useAppContext'
import PageLoading from '../../../components/UI/PageLoading/PageLoading'
import {SettingsPayments} from '../../../types/SettingsPayments/SettingsPayments'
import Button, {ButtonsIconAlign, ButtonStyles} from '../../../components/UI/Button/Button'
import CustomSelect from '../../../components/UI/CustomSelect/CustomSelect'
import SearchInput, {SearchInputStyles} from '../../../components/UI/SearchInput/SearchInput'
import PluginCard from '../../../components/PluginCard/PluginCard'
import {CustomGatewayPluginsExplorerService} from '../../../API/CustomGatewayPluginsExplorerService'
import {ExplorerPlugin} from '../../../types/Plugin/ExplorerPlugin'
import {useDebounce} from '../../../hooks/useDebounce'
import Spinner from '../../../components/UI/Spinner/Spinner'
import ConfigurePluginModal from '../../../components/Modals/ConfigurePluginModal/ConfigurePluginModal'
import CustomPaymentSetting from '../../../components/PaymentSetting/CustomPaymentSetting'
import BalancePaymentSetting from '../../../components/PaymentSetting/BalancePaymentSetting'
import ConfigureBalanceModal from '../../../components/Modals/ConfigurePaymentMethodModals/ConfigureBalanceModal'
import ConfigureStripeModal from '../../../components/Modals/ConfigurePaymentMethodModals/ConfigureStripeModal'
import {PaymentMethod} from '../../../types/PaymentMethod/PaymentMethod'
import {useNavigate} from 'react-router-dom'
import {Subscriptions} from '../../../types/Subscriptions/Subscriptions'
import {AvailablePayment} from '../../../types/AvailablePayment/AvailablePayment'
import {ShopForUserPermissions} from '../../../types/ShopForUserPermissions/ShopForUserPermissions'
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent'
import pageNotFoundImage from '../../../assets/images/404.png'
import Switch from '../../../components/UI/Switch/Switch'
import CustomMultiSelect from '../../../components/UI/CustomMultiSelect/CustomMultiSelect'
import Input, {InputIconAligns} from "../../../components/UI/FormComponents/Input";

const SettingsPaymentsPage = () => {
	const [isBalanceModalActive, setIsBalanceModalActive] = useState(false)
	const [isBalanceModalVisible, setIsBalanceModalVisible] = useState(false)
	const [isStripeModalActive, setIsStripeModalActive] = useState(false)
	const [isStripeModalVisible, setIsStripeModalVisible] = useState(false)
	const [editingAccountId, setEditingAccountId] = useState<number | null>(null)
	const {
		shop,
		user,
		setIsSubscriptionModalActive,
		setRequiredSubscription,
		setAvailablePayments,
	} = useAppContext()
	const navigate = useNavigate()
	const [searchPluginValue, setSearchPluginValue] = useState('')
	const [currentPlugin, setCurrentPlugin] = useState<ExplorerPlugin | null>()
	const [isPluginModalActive, setIsPluginModalActive] = useState(false)
	const [isPluginModalVisible, setIsPluginModalVisible] = useState(false)
	const [isConfiguringPlugin, setIsConfiguringPlugin] = useState(false)
	const [paymentMethodFilter, setPaymentMethodFilter] = useState<PaymentMethod | string>('')
	const [isMyPluginsTab, setIsMyPluginsTab] = useState(false)
	const [overrideShownPaymentMethods, setOverrideShownPaymentMethods] = useState<PaymentMethod[]>(
		[]
	)
	const [isOverrideShownPaymentMethodsEnabled, setIsOverrideShownPaymentMethodsEnabled] =
		useState(false)
	const [isAutomatedPriceRulesEnabled, setIsAutomatedPriceRulesEnabled] = useState(false);
	const [automatedPriceRulesPercentageTotal, setAutomatedPriceRulesPercentageTotal] = useState('');
	const [automatedPriceRulesFixedAmount, setAutomatedPriceRulesFixedAmount] = useState('');
	const [automatedPriceRulesChosenButton, setAutomatedPriceRulesChosenButton] = useState(0);

	const automatedPriceRulesButtons = ['Apply Fee', 'Apply Discount']

	const paymentMethodsOptions = useRef([
		{ label: 'Bank Card', value: PaymentMethod.BANK_CARD },
		{ label: 'Bank Transfer', value: PaymentMethod.BANK_TRANSFER },
		{ label: 'PayPal', value: PaymentMethod.PAYPAL },
		{ label: 'Cash App', value: PaymentMethod.CASHAPP },
		{ label: 'Cryptocurrency', value: PaymentMethod.CRYPTO },
		{ label: 'Apple and Google Pay', value: PaymentMethod.APPLE_AND_GOOGLE_PAY },
		{ label: 'Custom', value: PaymentMethod.CUSTOM },
		{ label: 'Select', value: '' },
	])
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isSettingsPaymentsViewAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_PAYMENTS_VIEW) || isAdmin
	const isSettingsPaymentsModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.SETTINGS_PAYMENTS_MODIFY) || isAdmin

	const [isGetPaymentsLoading, payments, getPayments] = useAPI<SettingsPayments>(
		() => PaymentsSettingsService.getPayments(shop?.id || ''),
		false
	)

	const [isGetPluginsLoading, plugins, getPlugins] = useAPI<ExplorerPlugin[]>(
		() =>
			CustomGatewayPluginsExplorerService.getAll(
				searchPluginValue,
				paymentMethodFilter,
				isMyPluginsTab
			),
		false
	)

	const [, , getAvailablePayments] = useAPI<AvailablePayment[]>(
		() => PaymentsSettingsService.getAvailablePayments(shop?.id || ''),
		false,
		undefined,
		{
			onSuccess: response => {
				setAvailablePayments(response.data.data || null)
			},
		}
	)

	useDebounce(searchPluginValue, getPlugins, 400)

	const [isUpdateGatewayLoading, , updateGateway] = useAPI(
		() =>
			PaymentsSettingsService.updateGateway(shop?.id || '', currentPlugin?.name || '', {
				accounts: [
					...(payments?.customGatewayAccounts
						.filter(
							account =>
								account.gatewayName === currentPlugin?.name && account.id !== editingAccountId
						)
						.map(account => ({
							id: account.id,
							savedSettings: account.savedSettings,
							isEnabled: account.isEnabled,
							maxAmountOfOrdersPerDay: account.maxAmountOfOrdersPerDay,
							maxAmountOfOrdersPerMonth: account.maxAmountOfOrdersPerMonth,
							maxAmountOfOrdersPerWeek: account.maxAmountOfOrdersPerWeek,
							maxRevenuePerDay: account.maxRevenuePerDay,
							maxRevenuePerMonth: account.maxRevenuePerMonth,
							maxRevenuePerWeek: account.maxRevenuePerWeek,
							priority: account.priority,
							blockVpnProxy: account?.blockVpnProxy || false,
							status: account.status,
						})) || []),
				],
				overrideShownPaymentMethods: isOverrideShownPaymentMethodsEnabled
					? overrideShownPaymentMethods
					: null,
				// @ts-ignore
				priceAdjustment: isAutomatedPriceRulesEnabled && (automatedPriceRulesPercentageTotal || automatedPriceRulesFixedAmount)
					? {
						isDiscount: automatedPriceRulesChosenButton === 1,
						percentage: automatedPriceRulesPercentageTotal
									?parseFloat(automatedPriceRulesPercentageTotal)
									: null,
                        fixedAmount: automatedPriceRulesFixedAmount
									? parseFloat(automatedPriceRulesFixedAmount)
									: null
					}
					: null
			}),
		false,
		'Payment settings successfully saved',
		{
			onSuccess: () => {
				getPayments()
			},
		}
	)

	const checkRequiredSubscription = (pluginName: string) => {
		if (pluginName.toLowerCase().includes('square')) {
			pluginName = 'Square'
		}

		switch (pluginName) {
			case "PayPalFF":
				return "BUSINESS"
			case "CoinbaseCommerce":
				return "BUSINESS"
			case "Cashapp":
				return "CORE_PLUS"
			case 'Square':
				return "CORE_PLUS"
			default:
				return "SCALE"
		}
	};

	const onPluginConfigureClickHandler = (plugin: ExplorerPlugin) => {
		if (shop?.subscription.perks.availableGateways.includes(plugin.name)) {
			setCurrentPlugin(plugin)
			setIsConfiguringPlugin(true)
		} else {
			console.log(plugin);
			setIsSubscriptionModalActive(true)
			setRequiredSubscription(Subscriptions[checkRequiredSubscription(plugin.name)])
		}
	}

	const closeBalanceModalHandler = () => {
		closeModal(setIsBalanceModalActive, setIsBalanceModalVisible)
	}

	const closePluginModal = () => {
		closeModal(setIsPluginModalActive, setIsPluginModalVisible)
		setEditingAccountId(null)
	}

	useEffect(() => {
		if (shop && isSettingsPaymentsViewAvailable) {
			getPayments()
			getPlugins()
		}
	}, [shop])

	useEffect(() => {
		if (shop) {
			getPlugins()
		}
	}, [paymentMethodFilter, isMyPluginsTab])

	useEffect(() => {
		if (currentPlugin && payments) {
			setOverrideShownPaymentMethods(payments?.overridePaymentMethods[currentPlugin.name] || [])
			setIsOverrideShownPaymentMethodsEnabled(
				!!payments?.overridePaymentMethods[currentPlugin.name]
			)

			setIsAutomatedPriceRulesEnabled(!!payments.priceAdjustments[currentPlugin.name]);
			if (!!payments.priceAdjustments[currentPlugin.name]) {
				setAutomatedPriceRulesPercentageTotal(payments.priceAdjustments[currentPlugin.name].percentage?.toString() ?? '');
				setAutomatedPriceRulesFixedAmount(payments.priceAdjustments[currentPlugin.name].fixedAmount?.toString() ?? '');
				setAutomatedPriceRulesChosenButton(payments.priceAdjustments[currentPlugin.name].isDiscount ? 1 : 0)
			}
		}
	}, [currentPlugin])

	if (isGetPaymentsLoading) return <PageLoading />

	if (!isSettingsPaymentsViewAvailable)
		return (
			<NoDataComponent
				isSmallHeight={true}
				imageSrc={pageNotFoundImage}
				title={'Access Denied'}
				description={
					"You don't have permissions to view this page on this team. Contact your administrator to access this dashboard."
				}
			/>
		)

	return (
		<PageWrapper>
			{isPluginModalActive && (
				<Modal
					closeModal={closePluginModal}
					visible={isPluginModalVisible}
					setVisible={setIsPluginModalVisible}
				>
					<ConfigurePluginModal
						plugin={currentPlugin}
						payments={payments}
						getPayments={() => {
							getPayments()
							getAvailablePayments()
						}}
						onClose={closePluginModal}
						editingAccountId={editingAccountId}
					/>
				</Modal>
			)}
			{isBalanceModalActive && (
				<Modal
					closeModal={closeBalanceModalHandler}
					visible={isBalanceModalVisible}
					setVisible={setIsBalanceModalVisible}
				>
					<ConfigureBalanceModal
						balanceSettings={payments?.balanceSettings}
						getPayments={() => {
							getPayments()
							getAvailablePayments()
						}}
						onClose={closeBalanceModalHandler}
					/>
				</Modal>
			)}
			{isStripeModalActive && (
				<Modal
					closeModal={() => closeModal(setIsStripeModalActive, setIsStripeModalVisible)}
					visible={isStripeModalVisible}
					setVisible={setIsStripeModalVisible}
				>
					<ConfigureStripeModal
						editingAccountId={editingAccountId}
						payments={payments}
						getPayments={() => {
							getPayments()
							getAvailablePayments()
						}}
						onClose={() => closeModal(setIsStripeModalActive, setIsStripeModalVisible)}
					/>
				</Modal>
			)}

			<div className={styles.settingsPageInnerBody}>
				<div className={styles.settingSection}>
					<header className={styles.settingSectionHeader}>
						<h1 className={styles.settingSectionTitle}>Payment Add-Ons</h1>
					</header>
					<div className={styles.settingSectionBody}>
						<div className={styles.fieldWrapper}>
							<p className={styles.fieldWrapperTitle}>Balance Setup</p>

							<div className={styles.formWrapper}>
								<BalancePaymentSetting
									isSettingsPaymentsModifyAvailable={isSettingsPaymentsModifyAvailable}
									onEditClick={() => {
										if (user?.subscription.perks.balance) {
											setIsBalanceModalActive(true)
										} else {
											setIsSubscriptionModalActive(true)
											setRequiredSubscription(Subscriptions.CORE_PLUS)
										}
									}}
									balanceSettings={payments?.balanceSettings}
								/>
							</div>
						</div>

						{/*<div className={styles.mtMediumLarge}>*/}
						{/*	<div className={styles.fieldWrapper}>*/}
						{/*		<header className={styles.fieldWrapperHeader}>*/}
						{/*			<div>*/}
						{/*				<p className={styles.fieldWrapperTitle}>Cover Platform Fees</p>*/}
						{/*			</div>*/}
						{/*			<Switch checked={true} setChecked={() => {}} />*/}
						{/*		</header>*/}
						{/*		<div className={styles.formWrapper}>*/}
						{/*			<Input value={'4.00'} setValue={() => {}} placeholder={'4.00'} />*/}
						{/*		</div>*/}
						{/*		<p className={styles.fieldWrapperDescription}>*/}
						{/*			If enabled, we’ll automatically add Billgang platform fees to the total your*/}
						{/*			customer has to pay.*/}
						{/*		</p>*/}
						{/*	</div>*/}
						{/*</div>*/}
					</div>
				</div>
				<div className={`${styles.settingSection}`}>
					<header className={styles.settingSectionHeader}>
						<p className={styles.settingSectionTitle}>
							{isConfiguringPlugin ? (
								`Edit ${currentPlugin?.displayName}`
							) : (
								<>
									Payment Integrations{' '}
									<span>
										({plugins?.length} plugin{plugins && plugins?.length > 1 && 's'})
									</span>
								</>
							)}
						</p>
					</header>

					{isConfiguringPlugin ? (
						currentPlugin && (
							<div className={styles.settingSectionBody}>
								<CustomPaymentSetting
									overridePaymentMethods={payments?.overridePaymentMethods[currentPlugin.name]}
									paymentAccounts={payments?.customGatewayAccounts}
									plugin={currentPlugin}
									shop={shop}
									onEditClick={() => {
									}}
									activateModal={() => {
										if (currentPlugin?.name === 'Stripe') {
											setIsStripeModalActive(true)
										} else {
											setIsPluginModalActive(true)
										}
									}}
									setEditingAccountId={setEditingAccountId}
									getPayments={getPayments}
									isSettingsPaymentsModifyAvailable={isSettingsPaymentsModifyAvailable}
								/>

								<div className={styles.mtMediumLarge}>
									<div className={styles.fieldWrapper}>
										<header className={styles.fieldWrapperHeader}>
											<div>
												<p className={styles.fieldWrapperTitle}>Customize Supported Payments</p>
												<p className={styles.fieldWrapperDescription}>
													This will allow you to disable or enable certain payment logos and
													names
													for {currentPlugin.displayName} during checkout.
												</p>
											</div>
											<Switch
												checked={isOverrideShownPaymentMethodsEnabled}
												setChecked={setIsOverrideShownPaymentMethodsEnabled}
											/>
										</header>

										{isOverrideShownPaymentMethodsEnabled && (
											<div className={styles.formWrapper}>
												<CustomMultiSelect
													value={overrideShownPaymentMethods}
													setValue={values =>
														setOverrideShownPaymentMethods(values as PaymentMethod[])
													}
													options={paymentMethodsOptions.current}
												/>
											</div>
										)}
									</div>
								</div>

								<div className={styles.mtMediumLarge}>
									<div className={styles.fieldWrapper}>
										<header className={styles.fieldWrapperHeader}>
											<div>
												<p className={styles.fieldWrapperTitle}>Automated Price Rules</p>
												<p className={styles.fieldWrapperDescription}>
													Display automatic price adjustments with fees or discounts
													calculated and deducted from the subtotal during customer checkout.
												</p>
											</div>
											<Switch
												checked={isAutomatedPriceRulesEnabled}
												setChecked={setIsAutomatedPriceRulesEnabled}
											/>
										</header>

										{
											isAutomatedPriceRulesEnabled && (
												<>
													<div className={styles.buttonsWrapper}>
														{
															automatedPriceRulesButtons.map((text, index) => (
																<Button
																	style={ButtonStyles.BORDERED}
																	key={index}
																	onClick={() => {setAutomatedPriceRulesChosenButton(index)}}
																	customClassname={automatedPriceRulesChosenButton === index
																		? styles.buttonsWrapperActive
																		: undefined}
																>
																	{text}
																</Button>
															))
														}
													</div>

													<div className={styles.inputsWrapper}>
														<div className={styles.inputsWrapperItem}>
															<Input
																value={automatedPriceRulesPercentageTotal}
																setValue={setAutomatedPriceRulesPercentageTotal}
																icon={{
																	id: 'percent',
																	height: 10,
																	width: 10,
																	align: InputIconAligns.LEFT
																}}
															/>
															<p>Percentage Total</p>
														</div>

														<div className={styles.inputsWrapperItem}>
															<Input
																value={automatedPriceRulesFixedAmount}
																setValue={setAutomatedPriceRulesFixedAmount}
																icon={{
																	id: 'dollar',
																	height: 11,
																	width: 11,
																	align: InputIconAligns.LEFT
																}}
															/>
															<p>Fixed Amount</p>
														</div>
													</div>
												</>
											)
										}
									</div>

									<div className={styles.mtMediumLarge}>
										<Button
											style={ButtonStyles.BORDERED}
											icon={{
												id: 'download',
												width: 13,
												height: 13,
												align: ButtonsIconAlign.LEFT,
											}}
											disableShadow={true}
											onClick={updateGateway}
											isLoading={isUpdateGatewayLoading}
										>
											Save Settings
										</Button>
									</div>
								</div>

								<div className={styles.mtMediumLarge}>
									<Button
										style={ButtonStyles.BORDERED}
										icon={{
											id: 'arrowLeft',
											width: 13,
											height: 12,
											align: ButtonsIconAlign.LEFT,
										}}
										width={'100%'}
										disableShadow={true}
										onClick={() => {
											setIsConfiguringPlugin(false)
											setCurrentPlugin(null)
										}}
									>
									Back
									</Button>
								</div>
							</div>
						)
					) : (
						<div className={styles.settingSectionBody}>
							<header className={paymentsStyles.pluginsHeader}>
								<div className={paymentsStyles.pluginsButtons}>
									<div
										className={`${paymentsStyles.pluginsButton} ${
											!isMyPluginsTab && paymentsStyles.active
										}`}
									>
										<Button
											style={ButtonStyles.BORDERED}
											disableShadow={true}
											onClick={() => setIsMyPluginsTab(false)}
										>
											All Plugins
										</Button>
									</div>

									<div
										className={`${paymentsStyles.pluginsButton} ${
											isMyPluginsTab && paymentsStyles.active
										}`}
									>
										<Button
											style={ButtonStyles.BORDERED}
											disableShadow={true}
											onClick={() => setIsMyPluginsTab(true)}
										>
											My Plugins
										</Button>
									</div>
								</div>

								<div className={paymentsStyles.pluginsSelects}>
									<CustomSelect
										value={paymentMethodFilter}
										setValue={setPaymentMethodFilter}
										options={paymentMethodsOptions.current}
									/>
								</div>
							</header>

							<div className={styles.mtMediumLarge}>
								<SearchInput
									style={SearchInputStyles.bordered}
									value={searchPluginValue}
									setValue={setSearchPluginValue}
									placeholder={'Search for payment integrations'}
								/>
							</div>

							{isGetPluginsLoading ? (
								<Spinner />
							) : (
								<div className={paymentsStyles.plugins}>
									{plugins?.map(plugin => (
										<PluginCard
											plugin={plugin}
											onEnableClick={() => onPluginConfigureClickHandler(plugin)}
											onConfigureClick={() => onPluginConfigureClickHandler(plugin)}
											isActive={
												!!payments?.customGatewayAccounts.find(
													account => account.gatewayName === plugin.name
												)
											}
											isSettingsPaymentsModifyAvailable={isSettingsPaymentsModifyAvailable}
											key={plugin.id}
										/>
									))}
								</div>
							)}
						</div>
					)}
				</div>

				<div className={`${styles.settingSection} ${styles.oneLine} `}>
					<header className={styles.settingSectionBody}>
						<p className={paymentsStyles.CTATitle}>Plugin Development Center</p>
						<p className={paymentsStyles.CTADescription}>
							You can create your own payment plugin by following our{' '}
							<a
								className={styles.anchorLink}
								href='https://developers.billgang.com/'
								target={'_blank'}
							>
								developer docs
							</a>{' '}
							or hire a freelance developer within our community to build one for you.
						</p>
					</header>
					<div className={styles.settingSectionBody}>
						<Button
							style={ButtonStyles.BORDERED}
							disableShadow={true}
							icon={{
								id: 'pen',
								width: 13,
								height: 13,
								align: ButtonsIconAlign.LEFT,
							}}
							onClick={() => navigate('/integrations/custom-plugins')}
						>
							Create a Plugin
						</Button>
					</div>
				</div>
			</div>
		</PageWrapper>
	)
}

export default SettingsPaymentsPage
