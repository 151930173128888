import React, { useEffect, useState } from 'react'
import styles from './OrderDetailedPage.module.scss'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import PageHeader from '../../components/PageHeader/PageHeader'
import Button, { ButtonsIconAlign, ButtonStyles } from '../../components/UI/Button/Button'
import OrdersCustomerData from './components/OrdersCustomerData/OrdersCustomerData'
import OrdersCustomerInformation from './components/OrdersCustomerInformation/OrdersCustomerInformation'
import OrdersCustomFields from './components/OrdersCustomFields/OrdersCustomFields'
import OrdersProductDelivered from './components/OrdersProductDelivered/OrdersProductDelivered'
import OrdersChargeDetails from './components/OrdersChargeDetails/OrdersChargeDetails'
import OrdersReplacementHistory from './components/OrdersReplacementHistory/OrdersReplacementHistory'
import { useNavigate, useParams } from 'react-router-dom'
import { useAPI } from '../../hooks/useAPI'
import { OrdersService } from '../../API/OrdersService'
import { useAppContext } from '../../hooks/useAppContext'
import { DetailedOrder } from '../../types/DetailedOrder/DetailedOrder'
import PageLoading from '../../components/UI/PageLoading/PageLoading'
import Modal from '../../components/Modals/Modal'
import ProcessOrderModal from '../../components/Modals/ProcessOrderModal/ProcessOrderModal'
import { closeModal } from '../../helpers/closeModal'
import ViewDeliveryModal from '../../components/Modals/ViewDeliveryModal/ViewDeliveryModal'
import ActionMenu, { ActionMenuStyles } from '../../components/UI/ActionMenu/ActionMenu'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'
import { useCopy } from '../../hooks/useCopy'
import BlacklistCustomerModal from '../../components/Modals/BlacklistCustomerModal/BlacklistCustomerModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import ResendOrderModal from '../../components/Modals/ResendOrderModal/ResendOrderModal'
import RefundOrderModal from '../../components/Modals/RefundOrderModal/RefundOrderModal'
import IssueReplacementModal from '../../components/Modals/IssueReplacementModal/IssueReplacementModal'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'

const OrderDetailedPage = () => {
	const params = useParams()
	const orderId = params.id
	const navigate = useNavigate()
	const { shop } = useAppContext()
	const [isOrderProcessModalActive, setIsOrderProcessModalActive] = useState(false)
	const [isOrderProcessModalVisible, setIsOrderProcessModalVisible] = useState(false)
	const [isViewDeliveryModalActive, setIsViewDeliveryModalActive] = useState(false)
	const [isViewDeliveryModalVisible, setIsViewDeliveryModalVisible] = useState(false)
	const [currentDeliveredProductId, setCurrentDeliveredProductId] = useState<null | number>(null)
	const [currentDeliveredProductQuantity, setCurrentDeliveredProductQuantity] = useState<
		null | number
	>(null)
	const [currentReplacementId, setCurrentReplacementId] = useState<null | number>(null)
	const [isActionMenuActive, setIsActionMenuActive] = useState(false)
	const [isBlacklistModalActive, setIsBlackListModalActive] = useState(false)
	const [isBlacklistModalVisible, setIsBlackListModalVisible] = useState(false)
	const [isRefundModalActive, setIsRefundModalActive] = useState(false)
	const [isRefundModalVisible, setIsRefundModalVisible] = useState(false)
	const [isResendModalActive, setIsResendModalActive] = useState(false)
	const [isResendModalVisible, setIsResendModalVisible] = useState(false)
	const [isIssueReplacementModalActive, setIsIssueReplacementModalActive] = useState(false)
	const [isIssueReplacementModalVisible, setIsIssueReplacementModalVisible] = useState(false)
	const isAdmin =
		!!shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		!!shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isOrdersModifyAvailable =
		!!shop?.permissions.includes(ShopForUserPermissions.ORDERS_MODIFY) || isAdmin
	const copy = useCopy()
	usePageTitle(`Order Details`)

	const [isGetOrderLoading, order, getOrder] = useAPI<DetailedOrder>(
		() => OrdersService.getOne(shop?.id || '', orderId),
		false
	)

	const actionMenuButtons: ActionMenuOption[] = [
		{
			title: 'Copy Order ID',
			icon: {
				id: 'blank',
				height: 11,
				width: 14,
			},
			onClick: () => copy(orderId || ''),
		},
	]

	isOrdersModifyAvailable &&
		actionMenuButtons.push(
			...[
				{
					title: 'Resend Order',
					icon: {
						id: 'pen',
						height: 14,
						width: 14,
					},
					onClick: () => setIsResendModalActive(true),
				},
				{
					title: 'Refund Payment	',
					icon: {
						id: 'dollar',
						height: 13,
						width: 13,
					},
					onClick: () => setIsRefundModalActive(true),
				},
			]
		)

	const onViewDeliveredProductClick = (id: number) => {
		setIsViewDeliveryModalActive(true)
		setCurrentDeliveredProductId(id)
	}

	const onReplacementClick = (id: number, quantity: number) => {
		setIsIssueReplacementModalActive(true)
		setCurrentDeliveredProductId(id)
		setCurrentDeliveredProductQuantity(quantity)
	}

	const onViewDeliveredReplacementClick = (productId: number, replacementId: number) => {
		setIsViewDeliveryModalActive(true)
		setCurrentDeliveredProductId(productId)
		setCurrentReplacementId(replacementId)
	}

	const closeBlacklistModal = () => {
		closeModal(setIsBlackListModalActive, setIsBlackListModalVisible)
	}

	useEffect(() => {
		if (shop) {
			getOrder()
		}
	}, [shop])

	if (isGetOrderLoading) return <PageLoading />

	return (
		<PageContainer>
			{isOrderProcessModalActive && (
				<Modal
					closeModal={() => closeModal(setIsOrderProcessModalActive, setIsOrderProcessModalVisible)}
					visible={isOrderProcessModalVisible}
					setVisible={setIsOrderProcessModalVisible}
				>
					<ProcessOrderModal
						chargeId={order?.charge.id}
						shopId={shop?.id || ''}
						getOrder={getOrder}
						onClose={() => closeModal(setIsOrderProcessModalActive, setIsOrderProcessModalVisible)}
					/>
				</Modal>
			)}
			{isIssueReplacementModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsIssueReplacementModalActive, setIsIssueReplacementModalVisible)
					}
					visible={isIssueReplacementModalVisible}
					setVisible={setIsIssueReplacementModalVisible}
				>
					<IssueReplacementModal
						shopId={shop?.id || ''}
						getOrder={getOrder}
						orderId={order?.id}
						partOrderQuantity={Number(currentDeliveredProductQuantity)}
						partOrderId={currentDeliveredProductId}
						onClose={() =>
							closeModal(setIsIssueReplacementModalActive, setIsIssueReplacementModalVisible)
						}
					/>
				</Modal>
			)}
			{isRefundModalActive && (
				<Modal
					closeModal={() => closeModal(setIsRefundModalActive, setIsRefundModalVisible)}
					visible={isRefundModalVisible}
					setVisible={setIsRefundModalVisible}
				>
					<RefundOrderModal
						customerEmail={order?.charge.customerForCharge?.email || ''}
						order={order}
						shopId={shop?.id || ''}
						onSuccess={getOrder}
						onClose={() => closeModal(setIsRefundModalActive, setIsRefundModalVisible)}
					/>
				</Modal>
			)}
			{isResendModalActive && (
				<Modal
					closeModal={() => closeModal(setIsResendModalActive, setIsResendModalVisible)}
					visible={isResendModalVisible}
					setVisible={setIsResendModalVisible}
				>
					<ResendOrderModal
						customerEmail={order?.charge.customerForCharge?.email}
						orderId={order?.id}
						shopId={shop?.id || ''}
						getOrder={getOrder}
						onClose={() => closeModal(setIsResendModalActive, setIsResendModalVisible)}
					/>
				</Modal>
			)}
			{isBlacklistModalActive && (
				<Modal
					closeModal={closeBlacklistModal}
					visible={isBlacklistModalVisible}
					setVisible={setIsBlackListModalVisible}
				>
					<BlacklistCustomerModal
						email={order?.charge.customerForCharge?.email || ''}
						onClose={closeBlacklistModal}
					/>
				</Modal>
			)}
			{isViewDeliveryModalActive && (
				<Modal
					closeModal={() => closeModal(setIsViewDeliveryModalActive, setIsViewDeliveryModalVisible)}
					visible={isViewDeliveryModalVisible}
					setVisible={setIsViewDeliveryModalVisible}
				>
					<ViewDeliveryModal
						currentDeliveredProductId={currentDeliveredProductId}
						currentReplacementId={currentReplacementId}
						partOrders={order?.partOrders}
						onClose={() => closeModal(setIsViewDeliveryModalActive, setIsViewDeliveryModalVisible)}
					/>
				</Modal>
			)}
			<PageWrapper>
				<div className={styles.orderDetailedPage}>
					<PageHeader
						title={order?.id || ''}
						isRow={true}
						backButton={{
							onClick: () => navigate('/finances/orders'),
							isActive: true,
						}}
					>
						<div className={styles.headerEnd}>
							{isOrdersModifyAvailable && (
								<>
									<Button
										style={ButtonStyles.SECONDARY}
										icon={{
											id: 'judgeHammer',
											width: 14,
											height: 14,
											align: ButtonsIconAlign.LEFT,
										}}
										onClick={() => setIsBlackListModalActive(true)}
									>
										Blacklist Customer
									</Button>
									<Button
										style={ButtonStyles.SECONDARY}
										icon={{
											id: 'truck',
											width: 14,
											height: 10,
											align: ButtonsIconAlign.LEFT,
										}}
										onClick={() => setIsOrderProcessModalActive(true)}
									>
										Process Order
									</Button>
								</>
							)}

							<ActionMenu
								style={ActionMenuStyles.light}
								actionButtons={actionMenuButtons}
								isActive={isActionMenuActive}
								setIsActive={setIsActionMenuActive}
							/>
						</div>
					</PageHeader>

					{order && (
						<div className={styles.body}>
							<div className={styles.column}>
								<OrdersCustomerData order={order} />
								{order.charge.customerForCharge?.deviceInfo && (
									<OrdersCustomerInformation order={order} />
								)}
								{order.metadata && !!Object.keys(order.metadata).length && (
									<OrdersCustomFields metadata={order.metadata} />
								)}
							</div>
							<div className={styles.column}>
								{order.partOrders && (
									<OrdersProductDelivered
										partOrders={order.partOrders}
										onViewClick={onViewDeliveredProductClick}
										onReplacementClick={onReplacementClick}
										isOrdersModifyAvailable={isOrdersModifyAvailable}
									/>
								)}
								{order.charge.customCharge && <OrdersChargeDetails orderCharge={order.charge} />}

								<OrdersReplacementHistory
									partOrders={order.partOrders}
									onViewDeliveredReplacementClick={onViewDeliveredReplacementClick}
								/>
							</div>
						</div>
					)}
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default OrderDetailedPage
