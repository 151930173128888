import { privateAPI } from './API'
import { CashAppAccountCreate } from '../types/CashAppAccountCreate/CashAppAccountCreate'
import { StripeAccountCreate } from '../types/StripeAccountCreate/StripeAccountCreate'
import { HoodpayAccountCreate } from '../types/HoodpayAccountCreate/HoodpayAccountCreate'
import { PayPalAccountCreate } from '../types/PayPalAccountCreate/PayPalAccountCreate'
import { CoinbaseCommerceAccountCreate } from '../types/CoinbaseCommerceAccountCreate/CoinbaseCommerceAccountCreate'
import { SquareAccountCreate } from '../types/SquareAccountCreate/SquareAccountCreate'
import { Gateways } from '../types/Gateways/Gateways'
import { PaymentAccountCreate } from '../types/PaymentAccountCreate/PaymentAccountCreate'
import { PutBalanceRewards } from '../types/PutBalanceRewards/PutBalanceRewards'
import { PaymentMethod } from '../types/PaymentMethod/PaymentMethod'
import {PaymentPriceAdjustment} from "../types/PaymentPriceAdjustment/PaymentPriceAdjustment";

export const PaymentsSettingsService = {
	getAvailablePayments: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/settings/payments/available-payments`)
	},
	getPayments: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/settings/payments`)
	},
	updateGateway: async (
		shopId: string,
		gateway: string,
		data: {
			accounts: PaymentAccountCreate[]
			overrideShownPaymentMethods: PaymentMethod[] | null
			priceAdjustment?: PaymentPriceAdjustment | null
		}
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/payments/${gateway}`, data)
	},
	updateCashapp: async (
		shopId: string,
		data: {
			cashappAccounts: CashAppAccountCreate[]
		}
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/payments/cashapp`, data)
	},
	updateStripe: async (
		shopId: string,
		data: {
			stripeAccounts: StripeAccountCreate[]
		}
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/payments/stripe`, data)
	},
	updateHoodpay: async (
		shopId: string,
		data: {
			hoodpayAccounts: HoodpayAccountCreate[]
		}
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/payments/hoodpay`, data)
	},
	updatePayPalEmail: async (
		shopId: string,
		data: {
			payPalEmailAccounts: PayPalAccountCreate[]
		}
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/payments/paypal-email`, data)
	},
	updatePayPalFF: async (
		shopId: string,
		data: {
			payPalFfAccounts: PayPalAccountCreate[]
		}
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/payments/paypal-ff`, data)
	},
	updateCoinbaseCommerce: async (
		shopId: string,
		data: {
			coinbaseCommerceAccounts: CoinbaseCommerceAccountCreate[]
		}
	) => {
		return await privateAPI.put(
			`/v1/dash/shops/${shopId}/settings/payments/coinbase-commerce`,
			data
		)
	},
	updateSquare: async (
		shopId: string,
		data: {
			squareAccounts: SquareAccountCreate[]
		}
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/payments/square`, data)
	},
	updateBalance: async (
		shopId: string,
		data: {
			isEnabled?: boolean
			defaultCurrency: string
			gateways: string[] | null
		}
	) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/payments/balance`, data)
	},
	updateBalanceRewards: async (shopId: string, data: PutBalanceRewards) => {
		return await privateAPI.put(`/v1/dash/shops/${shopId}/settings/payments/balance/rewards`, data)
	},
	getBalanceRewards: async (shopId: string) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/settings/payments/balance/rewards`)
	},
}
