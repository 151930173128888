import { useState } from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";

export const usePagination = (initialPageSize = 10) => {
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page');
    const [currentPage, setCurrentPage] = useState<number>(parseInt(page ?? '0'));

    const updateTotalItems = (total: number): void => {
        setTotalItems(total);
    };

    const handlePageChange = (newPage: number): void => {
        setCurrentPage(newPage);
        setSearchParams({page: String(newPage)})
    };

    const handlePageSizeChange = (newPageSize: number): void => {
        setPageSize(newPageSize);
        handlePageChange(0);
    };

    return {
        totalItems,
        currentPage,
        pageSize,
        setPageSize: handlePageSizeChange,
        setCurrentPage: handlePageChange,
        updateTotalItems,
    };
};