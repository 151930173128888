import React, {FC} from "react";
import RedesignedTable from "../../RedesignedTable/RedesignedTable";
import TableDropdown from "../../UI/TableDropdown/TableDropdown";
import {MenuProps} from "antd";
import DropdownMenuItem from "../../UI/DropdownMenuItem/DropdownMenuItem";
import {SpriteIcon} from "../../UI/SpriteIcon/SpriteIcon";
import styles from './AnalyticsReportHistoryTable.module.scss';
import Checkbox from "../../UI/Checkbox/Checkbox";
import {format} from "date-fns";
import {AnalyticsReport} from "../../../types/AnalyticsReports/AnalyticsReports";
import { countries } from "countries-list";

interface AnalyticsReportHistoryTableProps {
    reports: AnalyticsReport[];
    chosenReports: AnalyticsReport[];
    setChooseOneReport: (report: AnalyticsReport, value: boolean) => void;
    setChooseAllReports: (value: boolean) => void;
    onOpenReport: (index: number) => string;
}

const AnalyticsReportHistoryTable: FC<AnalyticsReportHistoryTableProps> = ({ reports, chosenReports, setChooseOneReport, setChooseAllReports, onOpenReport }) => {
    const dropdownMenu = (): any => {
        const items: MenuProps['items'] = [
            {
                label: <DropdownMenuItem
                    text={'? ? ? ? ? ?'}
                    iconId={'edit-pen'}
                />,
                key: '0',
                onClick: () => {}
            },
            {
                label: <DropdownMenuItem
                    text={'? ? ? ? ? ?'}
                    iconId={'delete-bin'}
                />,
                key: '1',
                onClick: () => {}
            }
        ]

        return { items };
    };

    const headerCheckbox = (
        <div className={styles.checkbox}>
            <Checkbox
                id={'chooseAll'}
                checked={reports.length > 0 && (chosenReports.length === reports.length)}
                setChecked={(value) => {
                    console.log(value);
                    setChooseAllReports(value);
                }}
            />
        </div>
    );

    const headers = [
        { element: headerCheckbox },
        { title: 'Products' },
        { title: 'Country' },
        { title: 'Customer' },
        { title: 'Date Range' },
        { title: 'Creation Date' },
        { title: 'Compare' },
        { title: '' }
    ];

    const rowsData = reports.map((report, index) => [
        <div className={styles.checkbox}>
            <Checkbox
                id={index.toString()}
                checked={chosenReports.includes(report)}
                setChecked={(value) => setChooseOneReport(report, value)}
            />
        </div>,

        <div className={styles.products}>
            {[0, 1, 2].map((number) => (
                <div className={styles.img} key={number}>
                    <SpriteIcon className={styles.arrowRight} iconId={'emptyImg'} width={20} height={20}/>
                </div>
            ))}

            <span>{
                !report.productsFilter && 'All Products'
            }</span>
        </div>,

        <div className={styles.country}>
            <span>
                {
                    report.countryFilter
                        ? report.countryFilter.length > 1
                            // @ts-ignore
                            ? countries[report.countryFilter[0]].name + ', +' + (report.countryFilter.length - 1) + ' more'
                            // @ts-ignore
                            : countries[report.countryFilter[0]].name
                        : 'All'
                }
            </span>
        </div>,

        <div className={styles.customer}>
            <span>{
                report.newCustomersFilter
                    ? 'New'
                    : report.newCustomersFilter === false
                        ? 'Existing'
                        : 'All'
            }</span>
        </div>,

        <div className={styles.dateRange}>
            <span>
                {format(new Date(report.fromDate), 'MMM dd, yyyy') + ' -...'}
            </span>
        </div>,

        <div className={styles.creationDate}>
            <span>{format(new Date(report.createdAt), 'MMM dd, yyyy')}</span>
            <p>at {format(new Date(report.createdAt), 'hh:mm aa z')}</p>
        </div>,

        <div className={styles.compare} style={{ color: 'var(--brand-color)' }}>
            {
                report.compareFromDate && report.compareToDate
                    ? <SpriteIcon iconId={'checked'} width={15} height={25} />
                    : <span>-</span>
            }
        </div>,

        <TableDropdown menuItems={dropdownMenu()} />
    ]);
    
    return <RedesignedTable
        headers={headers}
        rowsData={rowsData}
        hasCheckbox
        rowsHoverEffect
        onRowClick={(index) => {
            return onOpenReport(index);
        }}
    />;
};

export default AnalyticsReportHistoryTable;