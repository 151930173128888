import { privateAPI } from "./API";
import {CreateReportRequestData} from "../types/AnalyticsReports/AnalyticsReports";

export const ReportsService = {
    getReports: async (
        shopId: string,
        PageNumber?: number,
        PageSize?: number,
    ) => {
        return await privateAPI.get(`/v1/dash/shops/${shopId}/reports`, {
            params: {
                PageNumber,
                PageSize,
            },
        });
    },

    getReport: async (shopId: string, reportId: string) => {
        return await privateAPI.get(`/v1/dash/shops/${shopId}/reports/${reportId}`);
    },

    createReport: async (shopId: string, data: CreateReportRequestData) => {
        return await privateAPI.post(`/v1/dash/shops/${shopId}/reports`, data);
    }
};