import sprite from '../../../assets/images/sprite.svg'
import React from 'react'

interface SpriteIconProps {
	iconId: string
	width: number
	height: number
	rotate?: number
	className?: string
	withBox?: boolean
	onClick?: (event: React.MouseEvent) => void
}

export const SpriteIcon = ({ iconId, width, height, className, onClick, withBox, rotate }: SpriteIconProps) => {
	if (withBox) {
		return (
			<div style={{
				width: `${width}px`, 
				height: `${height}px`,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center' 
				}} onClick={onClick}>
				<svg className={className} width="85%" height="85%">
					<use href={`${sprite}#${iconId}`} />
				</svg>
			</div>
		)
	} else {
		return (
			<svg className={className} width={width} height={height} onClick={onClick} style={{ rotate: rotate ? `${rotate}deg` : '' }}>
				<use href={`${sprite}#${iconId}`} />
			</svg>
		)
	}
}
