import { privateAPI } from './API'

export const BoughtSubscriptionsService = {
	getSubscriptions: async (
		shopId: string,
		searchString?: string,
		statusFilter?: string,
		PageNumber?: number,
		PageSize?: number,
	) => {
		return await privateAPI.get(`/v1/dash/shops/${shopId}/subscriptions`, {
			params: {
				searchString,
				PageNumber,
				PageSize,
				statusFilter
			},
		})
	},
	getSubscriptionDetails: async (shopId: string, subscriptionId: string) => {
        return await privateAPI.get(`/v1/dash/shops/${shopId}/subscriptions/${subscriptionId}`)
    },
	cancelSubscription: async (shopId: string, subscriptionId: string) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/subscriptions/${subscriptionId}/cancel`)
	},
	extendSubscription: async (shopId: string, subscriptionId: string, date: string | null) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/subscriptions/${subscriptionId}/extend`, { expiresAt: date })
	},
	expireSubscription: async (shopId: string, subscriptionId: string, date: string | null) => {
		return await privateAPI.post(`/v1/dash/shops/${shopId}/subscriptions/${subscriptionId}/expire`, { expiresAt: date })
	}
}