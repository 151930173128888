import React, {FC, useState} from "react";
import {GraphWrapper} from "../GraphWrapper/GraphWrapper";
import {TopProduct} from "../../types/AnalyticsReportDetails/AnalyticsReportDetails";
import RedesignedTable from "../RedesignedTable/RedesignedTable";
import styles from "./AnalyticsGraphs.module.scss";
import {SpriteIcon} from "../UI/SpriteIcon/SpriteIcon";
import {format} from "date-fns";
import {WidgetEmpty} from "../WidgetEmpty/WidgetEmpty";

interface TopProductsAnalyticsProps {
    data: TopProduct[];
    dateRange: {
        fromDate: Date
        toDate: Date
    }
    compareDateRange?: {
        fromDate: Date
        toDate: Date
    }
    compareData?: TopProduct[];
    shouldCompare: boolean
}

const periods = ['This period', 'Previous period'];

const headers = [
    { title: ' ' },
    { title: 'Name' },
    { title: 'Orders' },
    { title: 'Revenue' },
];

const TopProductsAnalytics:FC<TopProductsAnalyticsProps> = ({ data, dateRange, compareDateRange, compareData, shouldCompare }) => {
    const [chosenPeriod, setChosenPeriod] = useState(0);

    const rowsData = ((chosenPeriod && compareData) ? compareData : data).map((product, index) => [
        <div>
            {index + 1}
        </div>,

        <div className={styles.productInfo}>
            <div className={styles.productInfoImg}>
                <SpriteIcon className={styles.arrowRight} iconId={'emptyImg'} width={20} height={20}/>
            </div>
            <div className={styles.productInfoText}>
                <span>{product.productName}</span>
                <p>{product.variantName}</p>
            </div>
        </div>,

        <div>{product.quantitySold}</div>,

        <div>${product.totalRevenueDefaultCurrency}</div>,

        <div></div>
    ]);

    return (
        <GraphWrapper title={'Top Products/Variants'}>
            {
                data.length ? (
                    <>
                        <p className={styles.dateCaption}>
                            {
                                !chosenPeriod
                                    ?
                                        dateRange.fromDate?.getFullYear() === dateRange.toDate?.getFullYear()
                                            ? format(dateRange.fromDate, 'MMM dd')
                                            + ' - ' + format(dateRange.toDate, 'MMM dd')
                                            : format(dateRange.fromDate, 'MMM dd, yyyy') +
                                            ' - ' + format(dateRange.toDate, 'MMM dd, yyyy')
                                    :
                                        compareDateRange?.fromDate?.getFullYear() === compareDateRange?.toDate?.getFullYear()
                                            ? format(compareDateRange?.fromDate ?? new Date(), 'MMM dd')
                                            + ' - ' + format(compareDateRange?.toDate ?? new Date(), 'MMM dd')
                                            : format(compareDateRange?.fromDate ?? new Date(), 'MMM dd, yyyy') +
                                            ' - ' + format(compareDateRange?.toDate ?? new Date(), 'MMM dd, yyyy')
                            }
                        </p>
                        {
                            shouldCompare && <div className={styles.periodSelector}>
                                {
                                    periods.map((text, index) => (
                                        <div
                                            className={`${styles.periodSelector__item} ${chosenPeriod === index && styles.selected}`}
                                            key={index}
                                            onClick={() => setChosenPeriod(index)}
                                        >
                                            {text}
                                        </div>
                                    ))
                                }
                            </div>
                        }
                        <RedesignedTable headers={headers} rowsData={rowsData} allowVerticalOverflow/>
                    </>
                ) : <WidgetEmpty/>
            }
        </GraphWrapper>
    )
};

export default TopProductsAnalytics;