import React, {FC, useEffect, useState} from "react";
import PageContainer from "../../components/UI/PageContainer/PageContainer";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import styles from './TelegramBotsPage.module.scss';
import PageHeader from "../../components/PageHeader/PageHeader";
import TelegramBotsPageActions from "./TelegramBotsPageActions/TelegramBotsPageActions";
import {StatusAlert} from "../../types/StatusAlert/StatusAlert";
import {ButtonsIconAlign} from "../../components/UI/Button/Button";
import StatusAlertBadge from "../../components/UI/StatusAlertBadge/StatusAlertBadge";
import {useAppContext} from "../../hooks/useAppContext";
import {useAPI} from "../../hooks/useAPI";
import {TelegramBot} from "../../types/TelegramBot/TelegramBot";
import {TelegramBotService} from "../../API/TelegramBotService";
import {useFirstLoading} from "../../hooks/useFirstLoading";
import PageLoading from "../../components/UI/PageLoading/PageLoading";
import Spinner from "../../components/UI/Spinner/Spinner";
import TelegramBotsTable from "../../components/Tables/TelegramBotsTable/TelegramBotsTable";
import Modal from "../../components/Modals/Modal";
import TelegramBotsPageModal from "./TelegramBotsPageModal/TelegramBotsPageModal";
import CreateTelegramBotModal from "../../components/Modals/CreateTelegramBotModal/CreateTelegramBotModal";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import noWebhookEndpoints from "../../assets/images/noWebhookEndpoints.png";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import TablePagination from "../../components/TablePagination/TablePagination";
import {usePagination} from "../../hooks/usePagination";
import {useAfterMountEffect} from "../../hooks/useAfterMountEffect";

const TelegramBotsPage: FC = () => {
    const [searchValue, setSearchValue] = useState('');

    const [token, setToken] = useState('');
    const [botName, setBotName] = useState('');
    const [supportUsername, setSupportUsername] = useState('');
    const [botToDeleteId, setBotToDeleteId] = useState('');
    const [botToEditId, setBotToEditId] = useState('');

    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

    const { shop } = useAppContext();
    const {
        totalItems,
        currentPage,
        pageSize,
        setPageSize,
        setCurrentPage,
        updateTotalItems
    } = usePagination();

    const [isGetBotsLoading, bots, getBots] = useAPI<TelegramBot[]>(
        () => TelegramBotService.getBots(shop?.id || '', searchValue, currentPage + 1, pageSize),
        false,
        undefined,
        {
            onSuccess: response => {
                updateTotalItems(response.headers['x-pagination-total'])
            },
        }
    )

    const [createBotLoading, , createBot] = useAPI(
        () => TelegramBotService.createBot(shop?.id || '', {
            token: token,
            name: botName,
            supportUsername: supportUsername
        }),
        false,
        undefined,
        {
            onSuccess: () => {
                setModalCreateIsOpen(false);
                getBots();
            }
        }
    );

    const [, , deleteBot] = useAPI(
        () => TelegramBotService.deleteBot(shop?.id || '', botToDeleteId),
        false,
        undefined,
        {
            onSuccess: () => {
                setModalDeleteIsOpen(false);
                getBots();
            }
        }
    )

    const [, , editBot] = useAPI(
        () => TelegramBotService.editBot(shop?.id || '',  botToEditId, {
            token: token,
            name: botName,
            supportUsername: supportUsername
        }),
        false,
        undefined,
        {
            onSuccess: () => {
                setModalEditIsOpen(false);
                getBots();
            }
        }
    )

    const isFirstLoading = useFirstLoading(isGetBotsLoading);

    useEffect(() => {
        shop && getBots();
    }, [shop, currentPage, pageSize]);

    useAfterMountEffect(() => {
        const timeout = setTimeout(() => {
            shop && getBots();
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [searchValue]);

    if (isFirstLoading) {
        return <PageLoading />
    }

    if (!bots?.length) {
        return  (
            <>
                {
                    modalCreateIsOpen && (
                        <Modal closeModal={() => {setModalCreateIsOpen(false)}} visible={modalCreateIsOpen} setVisible={() => {setModalCreateIsOpen(true)}}>
                            <CreateTelegramBotModal
                                buttonText={'Create Bot'}
                                buttonIconId={'addNew'}
                                botName={botName}
                                setBotName={(value) => {setBotName(value)}}
                                token={token}
                                setToken={(value) => {setToken(value)}}
                                supportUsername={supportUsername}
                                setSupportUsername={(value) => {setSupportUsername(value)}}
                                title={'Create Telegram Bot'}
                                onClose={() => {setModalCreateIsOpen(false)}}
                                onCreateBot={
                                    () => {
                                        createBot();
                                    }
                                }
                                createBotLoading={createBotLoading}
                            />
                        </Modal>
                    )
                }

                <NoDataComponent
                    imageSrc={noWebhookEndpoints}
                    title={'No Telegram Bots'}
                    description={
                        'You don’t have any Telegram bots. To create a bot, tap on the button below.'
                    }
                    button={
                        {
                            onClick: () => setModalCreateIsOpen(true),
                            text: 'New Telegram Bot',
                            icon: {
                                id: 'plus',
                                width: 13,
                                height: 13,
                                align: ButtonsIconAlign.LEFT,
                            },
                        }
                    }
                />

            </>
        )
    }

    return (
        <PageContainer>
            <PageWrapper>
                <div className={styles.telegramBotsPage}>
                    {
                        modalCreateIsOpen && (
                            <Modal closeModal={() => {setModalCreateIsOpen(false)}} visible={modalCreateIsOpen} setVisible={() => {setModalCreateIsOpen(true)}}>
                                <CreateTelegramBotModal
                                    buttonText={'Create Bot'}
                                    buttonIconId={'addNew'}
                                    botName={botName}
                                    setBotName={(value) => {setBotName(value)}}
                                    token={token}
                                    setToken={(value) => {setToken(value)}}
                                    supportUsername={supportUsername}
                                    setSupportUsername={(value) => {setSupportUsername(value)}}
                                    title={'Create Telegram Bot'}
                                    onClose={() => {setModalCreateIsOpen(false)}}
                                    onCreateBot={
                                    () => {
                                        createBot();
                                        }
                                    }
                                    createBotLoading={createBotLoading}
                                />
                            </Modal>
                        )
                    }

                    {
                        modalEditIsOpen && (
                            <Modal closeModal={() => {setModalEditIsOpen(false)}} visible={modalEditIsOpen} setVisible={() => {setModalEditIsOpen(true)}}>
                                <CreateTelegramBotModal
                                    buttonText={'Edit Bot'}
                                    buttonIconId={''}
                                    botName={botName}
                                    setBotName={(value) => {setBotName(value)}}
                                    token={token}
                                    setToken={(value) => {setToken(value)}}
                                    supportUsername={supportUsername}
                                    setSupportUsername={(value) => {setSupportUsername(value)}}
                                    title={'Edit Telegram Bot'}
                                    onClose={() => {setModalEditIsOpen(false)}}
                                    onCreateBot={
                                        () => {
                                            botToEditId && editBot();
                                            }
                                        }
                                    createBotLoading={createBotLoading}
                                />
                            </Modal>
                        )
                    }

                    {
                        modalDeleteIsOpen && (
                            <Modal closeModal={() => {setModalDeleteIsOpen(false)}} visible={modalDeleteIsOpen} setVisible={() => {setModalDeleteIsOpen(true)}}>
                                <ConfirmModal
                                    title={'Delete Bot'}
                                    description={'Are you sure you want to delete this bot?'}
                                    onConfirm={() => {
                                        botToDeleteId && deleteBot();
                                        setBotToDeleteId('');
                                        setModalDeleteIsOpen(false);
                                    }}
                                    confirmButtonText={'Delete'}
                                    onClose={() => {setModalDeleteIsOpen(false)}} />
                            </Modal>
                        )
                    }

                    <StatusAlertBadge
                        title={'Custom Hosting'}
                        description={
                            'We\'ve built powerful support for communities and businesses that are built through the Telegram platform. With custom hosting, you can merge internet commerce with your community. Learn more about how to get started with the button below.'
                        }
                        statusAlertType={StatusAlert.INFORMATION}
                        button={{
                            icon: {
                                id: 'blankWindow',
                                height: 13,
                                width: 13,
                                align: ButtonsIconAlign.LEFT,
                            },
                            text: 'View article',
                            onClick: () =>
                                window.open(
                                    'https://support.billgang.com/en/articles/9858771-how-do-i-create-a-custom-telegram-bot-and-start-accepting-telegram-stars-as-a-payment-type',
                                    'blank',
                                    'noopener'
                                ),
                        }}
                    />

                    <PageHeader title={'Telegram Bot'} noMarginBottom hasMarginTop />

                    <TelegramBotsPageActions searchValue={searchValue} setSearchValue={(value) => {
                        setSearchValue(value);
                    }} onCreateBot={() => {setModalCreateIsOpen(true)}} />

                    {isGetBotsLoading ? (
                        <Spinner />
                    ) : (
                        bots?.length ? (
                            <TelegramBotsTable
                                bots={bots}
                                onDeleteBot={(botId) => {
                                    setBotToDeleteId(botId);
                                    setModalDeleteIsOpen(true);
                                }}
                                onEditBot={(data) => {
                                    setBotToEditId(data.botId);
                                    setBotName(data.botName);
                                    setToken(data.botToken);
                                    setSupportUsername(data.supportUsername);
                                    setModalEditIsOpen(true);
                                }}
                            />
                        ) : null
                    )}

                    {
                        bots?.length ? (
                            <TablePagination
                                totalItems={totalItems}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                            />
                        ) : null
                    }
                </div>
            </PageWrapper>
        </PageContainer>
    )
};

export default TelegramBotsPage