import React, { FC, useState } from 'react'
import styles from './RecentActivity.module.scss'
import ActionMenu from '../UI/ActionMenu/ActionMenu'
import { ActionMenuOption } from '../../types/ActionMenuOption/ActionMenuOption'
import FeedbackActionMenu from '../FeedbackActionMenu/FeedbackActionMenu'
import { GetRecentActions } from '../../types/GetRecentActions/GetRecentActions'
import { NotificationEvent } from '../../types/NotificationEvent/NotificationEvent'
import { getRecentActionOption } from '../../helpers/getRecentActionOption'
import { capitalizeText } from '../../helpers/capitalizeText'
import { formatDistanceToNow } from 'date-fns'
import { Link } from 'react-router-dom'
import ContentLoader from 'react-content-loader'
import {WidgetEmpty} from "../WidgetEmpty/WidgetEmpty";

interface RecentActivityProps {
	recentActions?: GetRecentActions[]
}

const RecentActionSkeleton = () => {
	return (
		<ContentLoader
			viewBox='0 0 380 50'
			animate={false}
			backgroundColor={'var(--light_gray)'}
			foregroundColor={'var(--light_gray)'}
		>
			<rect x='0' y='0' rx='5' ry='5' width='35' height='35' />
			<rect x='50' y='5' rx='4' ry='4' width='300' height='10' />
			<rect x='50' y='20' rx='3' ry='3' width='250' height='8' />
		</ContentLoader>
	)
}

const RecentActivity: FC<RecentActivityProps> = ({ recentActions }) => {
	return (
		<div className={styles.recentActivity}>
			<header className={styles.header}>
				<h1>Recent Activity</h1>
				<FeedbackActionMenu />
			</header>
			<div className={styles.horLine} />
			{
				recentActions?.length ?
					(
						<div className={styles.actions}>
							{recentActions.map(recentAction => (
								<div className={styles.actionWrapper} key={recentAction.createdAt}>
									<Link
										to={getRecentActionOption(recentAction)?.link || ''}
										className={styles.action}
										key={recentAction.createdAt}
									>
										<div className={styles.actionHead}>
											<div className={styles.actionIcon}>
												{getRecentActionOption(recentAction)?.emoji}
											</div>
											<div>
												<h2 className={styles.actionTitle}>
													{getRecentActionOption(recentAction)?.text}
												</h2>
												<p className={styles.actionDescription}>
													{formatDistanceToNow(new Date(recentAction.createdAt))} ago
												</p>
											</div>
										</div>
									</Link>
								</div>
							))}
						</div>
					): <WidgetEmpty />
			}
		</div>
	)
}

export default RecentActivity;