import {useHandledData} from "./useHandledData";
import {StatisticsData} from "../types/StatisticsData/StatisticsData";

export const useHandledDataWithCompare = (
        compareData: StatisticsData[],
        dateRange: {
            fromDate: Date | null
            toDate: Date | null
        },
        handledData: {
            count: number;
            date: string;
            compareCount?: number;
        }[],
        shouldCompare: boolean
    ) => {
    const handledCompareData = useHandledData(compareData ?? [], dateRange, true).handledData;
    const fullData = [...handledData];

    shouldCompare && handledCompareData?.forEach((item) => {
        const index = fullData.findIndex(d => d.date === item.date);
        if (index !== -1) {
            fullData[index].compareCount = item.compareCount;
        } else {
            fullData.push(item);
        }
    });

    const compareCounts = () => {
        const counts = handledData.map((item) => item.count);
        const compareCounts = handledData.map((item) => item.compareCount ?? 0);

        return !compareCounts.length ? true : Math.max(...counts) > Math.max(...compareCounts)
    };

    return { fullData, compareCounts };
};