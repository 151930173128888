import React, { FC } from 'react'
import Input from '../FormComponents/Input'
import styles from './Search.module.scss'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'

export enum SearchInputStyles {
	secondary = 'secondary',
	bordered = 'bordered',
}

interface SearchInputProps {
	value: string
	setValue: (value: string) => void
	customClass?: string
	style?: SearchInputStyles
	placeholder?: string
	isDark?: boolean
	minWidth?: string
	width?: string
	borderRadius?: number
}

const SearchInput: FC<SearchInputProps> = ({
	value,
	setValue,
	customClass,
	style,
	placeholder,
	isDark,
	minWidth,
	width,
	borderRadius
}) => {
	return (
		<div
			className={`${styles.searchInput} ${customClass} ${style && styles[style]} ${
				isDark && styles.dark
			}`}
			style={{ minWidth: minWidth ?? undefined, width: width ?? undefined, borderRadius: borderRadius ?? undefined }}
		>
			<div className={styles.iconWrapper}>
				<SpriteIcon iconId={'searchIcon'} width={14} height={14} />
			</div>
			<Input value={value} setValue={setValue} placeholder={placeholder || ''} />
		</div>
	)
}

export default SearchInput
