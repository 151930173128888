import React, { useEffect, useState } from 'react'
import styles from './App.module.scss'
import Sidebar from './components/Sidebar/Sidebar'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import HomePage from './pages/HomePage/HomePage'
import ActionMessage from './components/UI/ActionMessage/ActionMessage'
import AllProductsPage from './pages/AllProductsPage/AllProductsPage'
import CreateProductPage from './pages/CreateProductPage/CreateProductPage'
import AllCategoriesPage from './pages/AllCategoriesPage/AllCategoriesPage'
import CreateCategoryPage from './pages/CreateCategoryPage/CreateCategoryPage'
import AllCouponsPage from './pages/AllCouponsPage/AllCouponsPage'
import CreateCouponPage from './pages/CreateCouponPage/CreateCouponPage'
import CustomersPage from './pages/CustomersPage/CustomersPage'
import CustomerDetailedPage from './pages/CustomerDetailedPage/CustomerDetailedPage'
import OrdersPage from './pages/OrdersPage/OrdersPage'
import OrderDetailedPage from './pages/OrderDetailedPage/OrderDetailedPage'
import ReviewsPage from './pages/ReviewsPage/ReviewsPage'
import ReviewDetailedPage from './pages/ReviewDetailedPage/ReviewDetailedPage'
import TicketsInboxPage from './pages/TicketsInboxPage/TicketsInboxPage'
import RewardsPage from './pages/RewardsPage/RewardsPage'
import RedirectPage from './pages/RedirectPage/RedirectPage'
import BlacklistPage from './pages/BlacklistPage/BlacklistPage'
import WhitelistPage from './pages/WhitelistPage/WhitelistPage'
import WebhooksPage from './pages/WebhooksPage/WebhooksPage'
import WebhookLogsPage from './pages/WebhookLogsPage/WebhookLogsPage'
import WebhookPayloadPage from './pages/WebhookPayloadPage/WebhookPayloadPage'
import AppStorePage from './pages/AppStorePage/AppStorePage'
import SettingsPage from './pages/Settings/SettingsPage'
import PostsPage from './pages/PostsPage/PostsPage'
import CreatePostPage from './pages/CreatePostPage/CreatePostPage'
import FAQPage from './pages/FAQPage/FAQPage'
import AuthPages from './pages/AuthPages/AuthPages'
import NoDataComponent from './components/NoDataComponent/NoDataComponent'
import automationComingSoon from './assets/images/automationComingSoon.png'
import linksComingSoon from './assets/images/LinksComingSoon.png'
import pageNotFoundImage from './assets/images/404.png'
import noReports from './assets/images/noReports.png'
import noCharges from './assets/images/noCharges.png'
import WarehousePage from './pages/WarehousePage/WarehousePage'
import ManageStockGroupPage from './pages/ManageStockGroupPage/ManageStockGroupPage'
import EditStockPage from './pages/ManageStockGroupPage/EditStockPage'
import ProductAnalyticsPage from './pages/ProductAnalyticsPage/ProductAnalyticsPage'
import AffiliatesMembersPage from './pages/AffiliatesMembersPage/AffiliatesMembersPage'
import { useAPI } from './hooks/useAPI'
import { UserService } from './API/UserService'
import { useAppContext } from './hooks/useAppContext'
import { User } from './types/User/User'
import PageLoading from './components/UI/PageLoading/PageLoading'
import ImagesPage from './pages/ImagesPage/ImagesPage'
import { Tooltip } from 'react-tooltip'
import DevelopersKeysPage from './pages/DevelopersKeysPage/DevelopersKeysPage'
import { PaymentsSettingsService } from './API/PaymentsSettingsService'
import { AvailablePayment } from './types/AvailablePayment/AvailablePayment'
import PluginsPage from './pages/PluginsPage/PluginsPage'
import AcceptInvitePage from './pages/AcceptInvitePage/AcceptInvitePage'
import PluginDetailPage from './pages/PluginDetailPage/PluginDetailPage'
import UpsellsPage from './pages/UpsellsPage/UpsellsPage'
import AddonsPage from './pages/AddonsPage/AddonsPage'
import Modal from './components/Modals/Modal'
import { closeModal } from './helpers/closeModal'
import SetupGoogleTFAModal from './components/Modals/SetupGoogleTFAModal/SetupGoogleTFAModal'
import UpgradeSubscription from './components/UpgradeSubscription/UpgradeSubscription'
import VerifyEmailPage from './pages/Verifications/VerifyEmailPage'
import { ShopForUserPermissions } from './types/ShopForUserPermissions/ShopForUserPermissions'
import verifyEmailImage from './assets/images/verifyEmail.png'
import { ButtonsIconAlign } from './components/UI/Button/Button'
import DiscordBotBuilderPage from './pages/DiscordBotBuilderPage/DiscordBotBuilderPage'
import AllGroupsPage from './pages/AllGroupsPage/AllGroupsPage'
import CreateGroupPage from './pages/CreateGroupPage/CreateGroupPage'
import EmbedsPage from './pages/EmbedsPage/EmbedsPage'
import PagesLayout from './components/PagesLayout/PagesLayout'
import SubscriptionsPage from './pages/SubscriptionsPage/SubscriptionsPage'
import TelegramBotsPage from "./pages/TelegramBotsPage/TelegramBotsPage";
import AdvancedAnalyticsPage from "./pages/AdvancedAnalyticsPage/AdvancedAnalyticsPage";
import AdvancedAnalyticsReportPage from "./pages/AdvancedAnalyticsReportPage/AdvancedAnalyticsReportPage";

function App() {
	const location = useLocation()
	const navigate = useNavigate()
	const isAuthPage = location.pathname.includes('auth')
	const isOnboardingPage = location.pathname.includes('onboarding')
	const {
		shop,
		setShop,
		setUser,
		setAvailablePayments,
		setRedirectAfterAuthPath,
		shopDomain,
		isSubscriptionModalActive,
		requiredSubscription,
		setIsSubscriptionModalActive,
	} = useAppContext()
	const [isGoogleTFAModalActive, setIsGoogleTFAModalActive] = useState(false)
	const [isGoogleTFAModalVisible, setIsGoogleTFAModalVisible] = useState(false)
	const isAdmin =
		shop?.permissions.includes(ShopForUserPermissions.OWNER) ||
		shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isProductsModifyAvailable =
		shop?.permissions.includes(ShopForUserPermissions.PRODUCTS_MODIFY) || isAdmin
	const isStockSystemModifyAvailable =
		shop?.permissions.includes(ShopForUserPermissions.STOCK_SYSTEMS_MODIFY) || isAdmin
	const isCouponsModifyAvailable =
		shop?.permissions.includes(ShopForUserPermissions.COUPONS_MODIFY) || isAdmin
	const isCategoriesModifyAvailable =
		shop?.permissions.includes(ShopForUserPermissions.CATEGORIES_MODIFY) || isAdmin
	const isOrdersViewAvailable =
		shop?.permissions.includes(ShopForUserPermissions.ORDERS_VIEW) || isAdmin
	const isCustomersViewAvailable =
		shop?.permissions.includes(ShopForUserPermissions.CUSTOMERS_VIEW) || isAdmin
	const isContentModifyAvailable =
		shop?.permissions.includes(ShopForUserPermissions.CONTENT_MODIFY) || isAdmin

	const [, , getAvailablePayments] = useAPI<AvailablePayment[]>(
		() => PaymentsSettingsService.getAvailablePayments(shop?.id || ''),
		false,
		undefined,
		{
			onSuccess: response => {
				setAvailablePayments(response.data.data || null)
			},
			onError: error => {
				if (error.response?.status === 403) {
					setIsGoogleTFAModalActive(true)
				}
			},
		}
	)

	const [isResendEmailVerificationLoading, , resendEmailVerification] = useAPI(
		() => UserService.resendEmailVerification(),
		false,
		'Email verification successfully resent'
	)

	const [isGetUserLoading, userData, getUser] = useAPI<User>(
		() => UserService.get(),
		false,
		undefined,
		{
			onError: error => {
				if (error.response?.status === 401) {
					if (location.pathname.includes('setup-store') || !location.pathname.includes('auth')) {
						navigate('/auth/sign-in')
						setRedirectAfterAuthPath(location.pathname)
					}

					localStorage.removeItem('accessToken')
					localStorage.removeItem('shopId')
				}
			},

		}
	)

	useEffect(() => {
		if (shop && userData) {
			// @ts-ignore
			window.Intercom('boot', {
				api_base: 'https://api-iam.intercom.io',
				app_id: 'wq68mxtc',
				email: userData.email,
				user_id: userData.id,
				shop_id: shop.id,
				name: shop.name,
				shop_link: shopDomain,
				subscription_id: userData.subscription?.id,
				hide_default_launcher: location.pathname === '/tickets/inbox',
			})

			getAvailablePayments()
		}
	}, [shop, userData])

	useEffect(() => {
		if (shop) {
			if (location.pathname === '/tickets/inbox') {
				// @ts-ignore
				window.Intercom('update', {
					hide_default_launcher: true,
				})
			} else {
				// @ts-ignore
				window.Intercom('update', {
					hide_default_launcher: false,
				})
			}
		}
	}, [location])

	useEffect(() => {
		if (userData) {
			const savedShopId = localStorage.getItem('shopId')
			if (savedShopId) {
				setShop(userData.shops.find(shop => shop.id === savedShopId) || null)
			} else {
				setShop(userData.shops[0])
			}

			if (userData.shops.length < 1) {
				navigate('/auth/setup-store')
			}

			setUser(userData)
		}
	}, [userData])

	useEffect(() => {
		if (!localStorage.getItem('accessToken') && !location.pathname.includes('auth')) {
			navigate('/auth/sign-in')
			setRedirectAfterAuthPath(location.pathname)
		}

		getUser()
	}, [])

	return (
		<div className={`${styles.app}`}>
			{isGetUserLoading ? (
				<PageLoading full={true} />
			) : (
				<>
					{isGoogleTFAModalActive && (
						<Modal
							closeModal={() => {}}
							visible={isGoogleTFAModalVisible}
							setVisible={setIsGoogleTFAModalVisible}
						>
							<SetupGoogleTFAModal
								onClose={() => closeModal(setIsGoogleTFAModalActive, setIsGoogleTFAModalVisible)}
								onSuccess={getUser}
								isEnabled={false}
								isRequired={true}
							/>
						</Modal>
					)}
					<ActionMessage />
					{!isAuthPage && !isOnboardingPage && userData && (
						<>
							<Sidebar subscription={userData.subscription} />
						</>
					)}
				</>
			)}

			<div
				className={`${styles.appPage} ${(isAuthPage || isOnboardingPage) && styles.withoutSidebar}`}
			>
				<Tooltip
					id='my-tooltip'
					style={{
						zIndex: 1999,
					}}
				/>

				{userData &&
				!userData.isEmailVerified &&
				!isAuthPage &&
				!location.pathname.includes('verifications') ? (
					<NoDataComponent
						imageSrc={verifyEmailImage}
						title={'Verify Email Address'}
						description={`To access the business dashboard, please verify your email via the link sent to your inbox at ${userData.email}. If you haven't received it, check your spam folder or request a new link.`}
						button={{
							icon: {
								id: 'refresh',
								width: 13,
								height: 13,
								align: ButtonsIconAlign.LEFT,
							},
							onClick: resendEmailVerification,
							isLoading: isResendEmailVerificationLoading,
							text: 'Resend Verification',
						}}
					/>
				) : (
					<ErrorBoundary>
						<PagesLayout isAuth={isAuthPage}>
							<Routes>
								<Route index path='/' element={<HomePage />} />
								<Route index path='/invite/:token/accept' element={<AcceptInvitePage />} />
								<Route index path='/verifications/email' element={<VerifyEmailPage />} />
								<Route
									index
									path='*'
									element={
										<NoDataComponent
											title={'Page Not Found'}
											description={
												'The page you are looking for may have been removed or is temporarily unavailable.'
											}
											imageSrc={pageNotFoundImage}
											button={{
												text: 'Go Home',
												onClick: () => navigate('/'),
											}}
										/>
									}
								/>
								<Route index path='/products' element={<AllProductsPage />} />
								{isProductsModifyAvailable && (
									<>
										<Route index path='/products/create' element={<CreateProductPage />} />
										<Route index path='/products/edit/:id' element={<CreateProductPage />} />
									</>
								)}
								<Route index path='/products/analytics/:id' element={<ProductAnalyticsPage />} />
								<Route index path='/products/categories' element={<AllCategoriesPage />} />
								<Route index path='/products/upsells' element={<UpsellsPage />} />
								<Route index path='/products/addons' element={<AddonsPage />} />
								{isCategoriesModifyAvailable && (
									<>
										<Route
											index
											path='/products/categories/create'
											element={<CreateCategoryPage />}
										/>
										<Route
											index
											path='/products/categories/edit/:id'
											element={<CreateCategoryPage />}
										/>
									</>
								)}

								<Route index path='/marketing/coupons' element={<AllCouponsPage />} />
								{isCouponsModifyAvailable && (
									<>
										<Route index path='/marketing/coupons/create' element={<CreateCouponPage />} />
										<Route
											index
											path='/marketing/coupons/edit/:id'
											element={<CreateCouponPage />}
										/>
									</>
								)}
								<Route index path='/products/groups' element={<AllGroupsPage />} />
								<Route index path='/products/groups/create' element={<CreateGroupPage />} />
								<Route index path='/products/groups/edit/:id' element={<CreateGroupPage />} />

								<Route index path='/products/warehouse' element={<WarehousePage />} />
								{isStockSystemModifyAvailable && (
									<>
										<Route
											index
											path='/products/warehouse/stock-groups/:id'
											element={<ManageStockGroupPage />}
										/>
										<Route
											index
											path='/products/warehouse/stock-groups/edit/:id'
											element={<EditStockPage />}
										/>
									</>
								)}

								<Route index path='/customers' element={<CustomersPage />} />
								{isCustomersViewAvailable && (
									<Route index path='/customers/:id' element={<CustomerDetailedPage />} />
								)}
								<Route index path='/finances/orders' element={<OrdersPage />} />
								{isOrdersViewAvailable && (
									<Route index path='/finances/orders/:id' element={<OrderDetailedPage />} />
								)}
								<Route index path='/finances' element={<RedirectPage url={'orders'} />} />
								<Route
									index
									path='/finances/charges'
									element={
										<NoDataComponent
											title={'Charges are coming soon'}
											description={
												'Create custom charges via this dashboard or our developer API and charge customers for products off platform.'
											}
											imageSrc={noCharges}
										/>
									}
								/>
								{/*<Route index path='/finances/charges/create' element={<CreatePaymentsChargePage />} />*/}

								<Route
									index
									path='/finances/subscriptions'
									element={
										<SubscriptionsPage />
									}
								/>

								<Route
									index
									path='/finances/subscriptions/:subscriptionId'
									element={
										<SubscriptionsPage />
									}
								/>

								<Route
									index
									path='/finances/links'
									element={
										<NoDataComponent
											title={'Links are coming soon'}
											description={
												'Create custom payment links for services or automate product delivery for a unique customer.'
											}
											imageSrc={linksComingSoon}
										/>
									}
								/>

								<Route index path={'/advanced-analytics'} element={<AdvancedAnalyticsPage />} />
								<Route index path={'/advanced-analytics/:id'} element={<AdvancedAnalyticsReportPage />} />

								<Route index path='/operations' element={<RedirectPage url='tickets/inbox' />} />

								<Route index path='/operations/reviews' element={<ReviewsPage />} />
								<Route index path='/operations/reviews/:id' element={<ReviewDetailedPage />} />
								<Route index path='/operations/tickets' element={<RedirectPage url={'/operations/tickets/inbox'} />} />
								<Route index path='/operations/tickets/inbox' element={<TicketsInboxPage />} />
								<Route index path='/operations/tickets/inbox/:id' element={<TicketsInboxPage />} />
								<Route
									index
									path='/tickets/automation'
									element={
										<NoDataComponent
											title={'Automation is coming soon'}
											description={
												'Set automated messages for certain keywords, first responses, and more.'
											}
											imageSrc={automationComingSoon}
										/>
									}
								/>
								<Route index path='/marketing' element={<RedirectPage url={'coupons'} />} />

								<Route
									index
									path='/marketing/affiliates'
									element={<RedirectPage url={'rewards'} />}
								/>
								<Route index path='/marketing/affiliates/rewards' element={<RewardsPage />} />
								<Route
									index
									path='/marketing/affiliates/members'
									element={<AffiliatesMembersPage />}
								/>
								<Route index path='/analytics' element={<RedirectPage url={'reports'} />} />
								{/*<Route index path='/analytics/insights' element={<AnalyticsInsightsPage />} />*/}
								<Route
									index
									path='/analytics/reports'
									element={
										<NoDataComponent
											title={'Reports are coming soon'}
											description={
												'Download business-critical data in CSV and PDF form for reporting purposes.'
											}
											imageSrc={noReports}
										/>
									}
								/>
								<Route
									index
									path='/integrations'
									element={<RedirectPage url={'discord-bot/builder'} />}
								/>
								<Route
									index
									path='/integrations/discord-bot'
									element={<RedirectPage url={'builder'} />}
								/>
								<Route
									index
									path='/integrations/discord-bot/builder'
									element={<DiscordBotBuilderPage />}
								/>
								<Route
									index
									path='/integrations/telegram-bot'
									element={<TelegramBotsPage />}
								/>

								<Route index path='/operations/blacklist' element={<BlacklistPage />} />
								<Route index path='/operations/whitelist' element={<WhitelistPage />} />
								<Route index path='/developers' element={<RedirectPage url={'api-keys'} />} />
								<Route index path='/developers/api-keys' element={<DevelopersKeysPage />} />
								<Route index path='/developers/embeds' element={<EmbedsPage />} />
								<Route index path='/developers/webhooks' element={<WebhooksPage />} />
								<Route index path='/developers/webhook-logs' element={<WebhookLogsPage />} />
								<Route
									index
									path='/developers/webhook-logs/:externalMessageId/:externalEndpointId'
									element={<WebhookPayloadPage />}
								/>
								<Route index path='/integrations/apps' element={<AppStorePage />} />
								<Route index path='/integrations/custom-plugins' element={<PluginsPage />} />
								<Route
									index
									path='/integrations/custom-plugins/:id'
									element={<PluginDetailPage />}
								/>
								<Route index path='/settings/*' element={<SettingsPage />} />
								<Route index path='/website' element={<RedirectPage url='posts' />} />
								<Route index path='/website/posts' element={<PostsPage />} />
								{isContentModifyAvailable && (
									<>
										<Route index path='/content/posts/create' element={<CreatePostPage />} />
										<Route index path='/content/posts/edit/:id' element={<CreatePostPage />} />
									</>
								)}
								<Route index path='/settings' element={<RedirectPage url='general' />} />

								<Route index path='/website/faq' element={<FAQPage />} />
								<Route index path='/operations/images' element={<ImagesPage />} />
								<Route index path='/auth/*' element={<AuthPages />} />
							</Routes>
						</PagesLayout>
					</ErrorBoundary>
				)}
			</div>

			<UpgradeSubscription
				isSubscriptionModalActive={isSubscriptionModalActive}
				setIsSubscriptionModalActive={setIsSubscriptionModalActive}
				requiredSubscription={requiredSubscription}
			/>
		</div>
	)
}

export default App
