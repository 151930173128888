import React, { useEffect, useState } from 'react'
import styles from './HomePage.module.scss'
import PageHeader from '../../components/PageHeader/PageHeader'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import { ButtonsIconAlign } from '../../components/UI/Button/Button'
import StatisticsItem from '../../components/StatisticsItem/StatisticsItem'
import OrdersTable from '../../components/Tables/OrdersTable'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import { useNavigate } from 'react-router-dom'
import DashboardWelcome from '../../components/DashboardWelcome/DashboardWelcome'
import RecentActivity from '../../components/RecentActivity/RecentActivity'
import Modal from '../../components/Modals/Modal'
import { closeModal } from '../../helpers/closeModal'
import CreateCustomerModal from '../../components/Modals/CreateCustomerModal/CreateCustomerModal'
import MessageCustomersModal from '../../components/Modals/MessageCustomersModal/MessageCustomersModal'
import { useAPI } from '../../hooks/useAPI'
import { Order } from '../../types/Order/Order'
import { OrdersService } from '../../API/OrdersService'
import { useAppContext } from '../../hooks/useAppContext'
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent'
import noOrdersImage from '../../assets/images/noOrders.png'
import StatusAlertBadge from '../../components/UI/StatusAlertBadge/StatusAlertBadge'
import { StatusAlert } from '../../types/StatusAlert/StatusAlert'
import AdvancedFiltering from '../../components/AdvancedFiltering/AdvancedFiltering'
import { AnalyticsService } from '../../API/AnalyticsService'
import { getDatePeriod } from '../../helpers/getDatePeriod'
import { GetRecentActions } from '../../types/GetRecentActions/GetRecentActions'
import { GetSalesChart } from '../../types/GetSalesChart/GetSalesChart'
import { DatePeriod } from '../../types/DatePeriod/DatePeriod'
import Spinner from '../../components/UI/Spinner/Spinner'
import { usePageTitle } from '../../hooks/usePageTitle'
import DateController from '../../components/UI/DateController/DateController'
import { UserService } from '../../API/UserService'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'
import ConversionRate from '../../components/ConversionRate/ConversionRate'
import TrafficAcquisition from '../../components/TrafficAcquisition/TrafficAcquisition'
import TopProducts from '../../components/TopProducts/TopProducts'
import TopPayments from '../../components/TopPayments/TopPayments'
import { AnalyticsVisitsService } from '../../API/AnalyticsVisitsService'
import { GetAnalyticsVisit } from '../../types/GetAnalyticsVisit/GetAnalyticsVisit'
import { GetConversionRates } from '../../types/GetConversionRates/GetConversionRates'
import { TopProductChart } from '../../types/TopProductChart/TopProductChart'
import OrdersHistoryTable from "../../components/Tables/OrdersHistoryTable/OrdersHistoryTable";
import {Table} from "antd";
import TablePagination from "../../components/TablePagination/TablePagination";

enum MainCharts {
	revenue,
	visits,
	orders,
}

interface GatewayCounts {
	[gatewayName: string]: {
		count: number
		amount: number
	}
}

const HomePage = () => {
	const navigate = useNavigate()
	const [selectDate, setSelectDate] = useState<DatePeriod>(DatePeriod.lastDay)
	const [currentChart, setCurrentChart] = useState(MainCharts.revenue)
	const [isCreateCustomerModalActive, setIsCreateCustomerModalActive] = useState(false)
	const [isCreateCustomerModalVisible, setIsCreateCustomerModalVisible] = useState(false)
	const [isMessageCustomersModalActive, setIsMessageCustomersModalActive] = useState(false)
	const [isMessageCustomersModalVisible, setIsMessageCustomersModalVisible] = useState(false)
	const [dateRanges, setDateRanges] = useState<{
		fromDate: Date | null
		toDate: Date | null
	}>({
		toDate: new Date(),
		fromDate: getDatePeriod.getLastDayDate(),
	})
	const { shop, user } = useAppContext()
	const isOwner = shop?.permissions.includes(ShopForUserPermissions.OWNER)
	const isAdmin = shop?.permissions.includes(ShopForUserPermissions.ADMIN)
	const isAnalyticsViewAvailable =
		shop?.permissions.includes(ShopForUserPermissions.ANALYTICS_VIEW) || isOwner || isAdmin
	const isOrdersViewAvailable =
		shop?.permissions.includes(ShopForUserPermissions.ORDERS_VIEW) || isOwner || isAdmin

	usePageTitle('Dashboard')

	const [isGetOrdersLoading, orders, getOrders] = useAPI<Order[]>(
		() => OrdersService.getAll(shop?.id || '', null, undefined, 1, 5),
		false
	)

	const [isGetRecentActionsLoading, recentActions, getRecentActions] = useAPI<GetRecentActions[]>(
		() => AnalyticsService.getRecentActions(shop?.id || ''),
		false
	)

	const [isGetSalesChartsLoading, salesCharts, getSalesChats] = useAPI<GetSalesChart[]>(() => {
		return AnalyticsService.getSalesCharts(shop?.id || '', dateRanges.fromDate, dateRanges.toDate)
	}, false)

	const [isGetVisitsLoading, visits, getVisits] = useAPI<GetAnalyticsVisit[]>(() => {
		return AnalyticsVisitsService.getVisits(shop?.id || '', dateRanges.fromDate, dateRanges.toDate)
	}, false)

	const [isGetTopProductsCharts, topProductsCharts, getTopProductsCharts] = useAPI<
		TopProductChart[]
	>(() => {
		return AnalyticsService.getTopProductsCharts(
			shop?.id || '',
			dateRanges.fromDate,
			dateRanges.toDate
		)
	}, false)

	const [isGetConversionRates, conversionRates, getConversionRates] = useAPI<GetConversionRates>(
		() => {
			return AnalyticsVisitsService.getConversionRates(
				shop?.id || '',
				dateRanges.fromDate,
				dateRanges.toDate
			)
		},
		false
	)

	const gatewayCounts: GatewayCounts | undefined = salesCharts?.reduce(
		(counts: GatewayCounts, { gatewayName, amount }) => {
			counts[gatewayName] = {
				amount: (counts[gatewayName]?.amount || 0) + amount,
				count: (counts[gatewayName]?.count || 0) + 1,
			}
			return counts
		},
		{}
	)

	useEffect(() => {
		if (shop) {
			if (isOrdersViewAvailable) {
				getOrders()
			}
			getRecentActions()
		}
	}, [shop])

	useEffect(() => {
		if (shop && isAnalyticsViewAvailable) {
			getSalesChats()
			getVisits()
			getConversionRates()
			getTopProductsCharts()
		}
	}, [dateRanges, shop])

	return (
		<PageContainer>
			{isCreateCustomerModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsCreateCustomerModalActive, setIsCreateCustomerModalVisible)
					}
					visible={isCreateCustomerModalVisible}
					setVisible={setIsCreateCustomerModalVisible}
				>
					<CreateCustomerModal
						onClose={() =>
							closeModal(setIsCreateCustomerModalActive, setIsCreateCustomerModalVisible)
						}
					/>
				</Modal>
			)}
			{isMessageCustomersModalActive && (
				<Modal
					closeModal={() =>
						closeModal(setIsMessageCustomersModalActive, setIsMessageCustomersModalVisible)
					}
					visible={isMessageCustomersModalVisible}
					setVisible={setIsMessageCustomersModalVisible}
				>
					<MessageCustomersModal
						onClose={() =>
							closeModal(setIsMessageCustomersModalActive, setIsMessageCustomersModalVisible)
						}
					/>
				</Modal>
			)}
			<PageWrapper>
				<div className={styles.homePage}>
					<PageHeader title={'Dashboard'}></PageHeader>

					<DashboardWelcome />

					{isAnalyticsViewAvailable && (
						<DateController
							dateRanges={dateRanges}
							setDateRanges={setDateRanges}
							selectDate={selectDate}
							setSelectDate={setSelectDate}
							isLoading={isGetSalesChartsLoading}
						/>
					)}

					<div className={styles.mainGrid}>
						{isGetSalesChartsLoading ||
						isGetVisitsLoading ||
						isGetConversionRates ||
						isGetTopProductsCharts ? (
							<Spinner />
						) : (
							isAnalyticsViewAvailable && (
								<div className={styles.mainColumn}>
									<StatisticsItem
										title={'Revenue'}
										width={'100'}
										height={180}
										isMoney={true}
										data={
											salesCharts?.map(item => ({
												count: item.amount,
												date: item.createdAt,
											})) || []
										}
										datePeriod={selectDate}
										dateRange={dateRanges}
									/>
									<div className={styles.twoColumns}>
										{visits && <TrafficAcquisition datePeriod={selectDate} data={visits} />}
										{conversionRates && (
											<ConversionRate data={conversionRates} datePeriod={selectDate} />
										)}
										{topProductsCharts && (
											<TopProducts datePeriod={selectDate} data={topProductsCharts} />
										)}
										<TopPayments
											datePeriod={selectDate}
											data={Object.entries(gatewayCounts || {}).map(value => ({
												count: value[1].count,
												amount: value[1].amount,
												gatewayName: value[0],
											}))}
										/>
									</div>
								</div>
							)
						)}

						<div className={styles.mainGridColumn}>
							{isAnalyticsViewAvailable && (
								<StatisticsItem
									title={'Orders'}
									width={'100'}
									height={180}
									data={
										salesCharts?.map(item => ({
											count: 1,
											date: item.createdAt,
										})) || []
									}
									datePeriod={selectDate}
									dateRange={dateRanges}
								/>
							)}
							{isGetRecentActionsLoading ? (
								<Spinner />
							) : (
								<RecentActivity recentActions={recentActions} />
							)}
						</div>
					</div>

					{/*<div className={styles.statisticsMediumGrid}>*/}
					{/*	<CustomerRetention />*/}
					{/*	<PaymentMethodStatistics />*/}
					{/*</div>*/}

					{orders?.length ? (
						<>
							<div className={styles.ordersPanel}>
								<div className={styles.ordersPanelButtons}>
									{/*<Button*/}
									{/*	style={ButtonStyles.SECONDARY}*/}
									{/*	icon={{*/}
									{/*		id: 'plus',*/}
									{/*		width: 13,*/}
									{/*		height: 13,*/}
									{/*		align: ButtonsIconAlign.LEFT,*/}
									{/*	}}*/}
									{/*	onClick={() => setIsCreateCustomerModalActive(true)}*/}
									{/*>*/}
									{/*	New Customer*/}
									{/*</Button>*/}
									{/*<Button*/}
									{/*	style={ButtonStyles.SECONDARY}*/}
									{/*	icon={{*/}
									{/*		id: 'send',*/}
									{/*		width: 12,*/}
									{/*		height: 14,*/}
									{/*		align: ButtonsIconAlign.LEFT,*/}
									{/*	}}*/}
									{/*	onClick={() => setIsMessageCustomersModalActive(true)}*/}
									{/*>*/}
									{/*	Message 5*/}
									{/*</Button>*/}
									{/*<Button*/}
									{/*	style={ButtonStyles.SECONDARY}*/}
									{/*	icon={{*/}
									{/*		id: 'download',*/}
									{/*		width: 14,*/}
									{/*		height: 14,*/}
									{/*		align: ButtonsIconAlign.LEFT,*/}
									{/*	}}*/}
									{/*>*/}
									{/*	Export CSV*/}
									{/*</Button>*/}
									<AdvancedFiltering />
								</div>
							</div>

							{isOrdersViewAvailable && (
								<>
									<p className={styles.tableTitle}>Recent orders</p>
									<OrdersHistoryTable orders={orders} menuIsHidden />
									<TablePagination
										totalItems={orders.length}
										pageSize={5}
										setPageSize={() => {}}
										setCurrentPage={() => {}}
										currentPage={0}
										withoutPageSizeSelector
										withoutPages
									/>
								</>
							)}
						</>
					) : (
						<div className={styles.noOrders}>
							<NoDataComponent
								imageSrc={noOrdersImage}
								title={'No Recent Orders'}
								isSmallHeight={true}
								description={
									'When a customer creates an order on your storefront, it will appear here.'
								}
							/>
						</div>
					)}
				</div>
			</PageWrapper>
		</PageContainer>
	)
}

export default HomePage
