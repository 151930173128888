import {FC, useEffect, useState} from "react";
import PageContainer from "../../components/UI/PageContainer/PageContainer";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import styles from './AdvancedAnalyticsPage.module.scss';
import AdvancedAnalyticsPageHeader from "./AdvancedAnalyticsPageHeader/AdvancedAnalyticsPageHeader";
import AnalyticsReportHistoryTable
    from "../../components/Tables/AnalyticsReportHistoryTable/AnalyticsReportHistoryTable";
import {usePagination} from "../../hooks/usePagination";
import TablePagination from "../../components/TablePagination/TablePagination";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import { countries } from "countries-list";
import Modal from "../../components/Modals/Modal";
import CreateAnalyticsPresetModal from "../../components/Modals/CreateAnalyticsPresetModal/CreateAnalyticsPresetModal";
import {useAPI} from "../../hooks/useAPI";
import {ReportsService} from "../../API/ReportsService";
import {useAppContext} from "../../hooks/useAppContext";
import PageLoading from "../../components/UI/PageLoading/PageLoading";
import {AnalyticsReport, CreateReportRequestData} from "../../types/AnalyticsReports/AnalyticsReports";
import {useNavigate} from "react-router-dom";
import {ProductsService} from "../../API/ProductsService";
import {Product} from "../../types/Product/Product";
import {useAfterMountEffect} from "../../hooks/useAfterMountEffect";

// @ts-ignore
const countriesNames = Object.keys(countries).map((key) => countries[key].name);

const availableCountries = countriesNames;

const AdvancedAnalyticsPage: FC = () => {
    const [productsLoading, products, getAvailableProducts] = useAPI<Product[]>(
        () => ProductsService.getProductsWithVariants(shop?.id || ''),
        false,
        undefined,
        {
            onSuccess: (response) => {
                console.log(response.data.data)
            }
        }
    );

    const [analyticsOptions, setAnalyticsOptions] = useState<Record<string, any>>({
        products: products?.map((product) => product.name) ?? [],
        customer: 'All',
        countries: [...availableCountries],
        dateRange: 'This Month',
        dateRangeFrom: null,
        dateRangeTo: null,
        compareToPrevious: false,
        compareFromDate: new Date(),
        compareToDate: new Date(),
    });
    const [chosenReports, setChosenReports] = useState<any[]>([]);
    const [createPresetModalIsOpen, setCreatePresetModalIsOpen] = useState(false);

    const {
        pageSize,
        currentPage,
        updateTotalItems,
        totalItems,
        setCurrentPage,
        setPageSize
    } = usePagination();
    const { shop } = useAppContext();
    const navigate = useNavigate();
    const usersTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const getChosenCountriesCodes = (): string[] => {
        return analyticsOptions.countries.map((value: string) => {
            // @ts-ignore
            return Object.keys(countries).find((key) => countries[key].name === value);
        })
    };

    const [reportsLoading, reports, getReports] = useAPI<AnalyticsReport[]>(
        () => ReportsService.getReports(shop?.id || '', currentPage + 1, pageSize),
        false,
        undefined,
        {
            onSuccess: (response) => { updateTotalItems(response.headers['x-pagination-total']) }
        }
    );

    const [createReportLoading, , createReport] = useAPI(
        () => ReportsService.createReport(shop?.id || '', {
            type: "PRODUCTS",
            timezone: usersTimezone,
            fromDate: analyticsOptions.dateRangeFrom?.toISOString().split('T', 1)[0],
            toDate: analyticsOptions.dateRangeTo?.toISOString().split('T', 1)[0],
            compareFromDate: analyticsOptions.compareToPrevious? analyticsOptions.compareFromDate.toISOString().split('T', 1)[0] : null,
            compareToDate: analyticsOptions.compareToPrevious? analyticsOptions.compareToDate.toISOString().split('T', 1)[0] : null,
            productsFilter: null,
            countryFilter: analyticsOptions.countries.length === availableCountries.length
                            ? null
                            : getChosenCountriesCodes(),
            newCustomersFilter: analyticsOptions.customer === 'All'
                                ? null
                                : analyticsOptions.customer === 'New'
        }),
        false,
        undefined,
        {
            onSuccess: (response: { data: { data: { id: string } } }) => {
                navigate(`${response.data.data.id}`)
            }
        }
    );

    useEffect(() => {
        shop && getReports();
        shop && getAvailableProducts();
    }, [shop]);

    useEffect(() => {
        setAnalyticsOptions({
            ...analyticsOptions,
            products: products?.map((product) => product.name)
        })
    }, [products])

    if (reportsLoading || productsLoading || !products) {
        return <PageLoading />;
    }

    return (
        <PageContainer>
            <PageWrapper>
                {
                    createPresetModalIsOpen && (
                        <Modal
                            closeModal={() => {setCreatePresetModalIsOpen(false)}}
                            visible={createPresetModalIsOpen}
                            setVisible={() => {setCreatePresetModalIsOpen(true)}}
                        >
                            <CreateAnalyticsPresetModal onClose={() => {setCreatePresetModalIsOpen(false)}} />
                        </Modal>
                    )
                }

                <div className={styles.analyticsPage}>
                    <AdvancedAnalyticsPageHeader
                        options={analyticsOptions}
                        setOptions={(options) => { setAnalyticsOptions(options) }}
                        availableProducts={products.map(product => product.name)}
                        availableCountries={availableCountries}
                        openCreatePresetModal={() => {setCreatePresetModalIsOpen(true)}}
                        onCreateReport={createReport}
                        createReportLoading={createReportLoading}
                    />

                    <div className={styles.table}>
                        <h3>Report History</h3>
                    </div>

                    {
                        reports?.length
                            ? (
                                <>
                                    <AnalyticsReportHistoryTable
                                        reports={reports}
                                        chosenReports={chosenReports}
                                        setChooseOneReport={(report, value) => {
                                            value
                                                ? setChosenReports([...chosenReports, report])
                                                : setChosenReports(chosenReports.filter(r => r !== report))
                                        }}
                                        setChooseAllReports={(value) => {
                                            value
                                                ? setChosenReports(reports)
                                                : setChosenReports([]);
                                        }}
                                        onOpenReport={(index) => {
                                            return `${reports[index].id}`
                                        }}
                                    />
                                    <TablePagination
                                        totalItems={totalItems}
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                    />
                                </>
                                )
                            : (
                                <NoDataComponent
                                    title={'No History Yet'}
                                    description={'All of your analytics creations will be stored here.'}
                                    isSmallHeight
                                />
                            )
                    }
                </div>
            </PageWrapper>
        </PageContainer>
    )
};

export default AdvancedAnalyticsPage;