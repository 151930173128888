export function getBreadcrumbs(path: string): string[] {
	const parts = path.split('/').filter(Boolean);
	return parts.map(part => {
		const isId = /^[a-f0-9-]{8,}$/.test(part);
		if (isId) {
			return `ID: ${part}`;
		}

		return part.charAt(0).toUpperCase() + part.slice(1);
	});
}