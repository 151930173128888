import React, { FC, useRef } from 'react'
import styles from './Tables.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import Button, { ButtonsIconAlign, ButtonStyles } from '../UI/Button/Button'
import { AuditLog } from '../../types/AuditLog/AuditLog'
import { ShopForUserPermissions } from '../../types/ShopForUserPermissions/ShopForUserPermissions'
import { format } from 'date-fns'
import { getAction } from '../../helpers/getActionFromEndpoints'
import RedesignedTable from "../RedesignedTable/RedesignedTable";

interface AuditLogTableProps {
	onViewDetailsClick: (auditLog: AuditLog) => void
	auditLogs: AuditLog[]
}

const AuditLogTable: FC<AuditLogTableProps> = ({ onViewDetailsClick, auditLogs }) => {
	const headers = [
		{ title: 'Event' },
		{ title: 'Team Member' },
		{ title: 'Date' },
		{ title: '' },
	];

	const rowsData = auditLogs.map((auditLog: AuditLog) => [
		<p>{getAction({method: auditLog.method, path: auditLog.url})}</p>,

		<div className={`${styles.itemInfo} ${styles.secondary}`}>
			{auditLog.user.permissions.includes(ShopForUserPermissions.ADMIN) ||
			auditLog.user.permissions.includes(ShopForUserPermissions.OWNER) ? (
				<SpriteIcon iconId={'crown'} width={14} height={14}/>
			) : (
				<SpriteIcon iconId={'personCircle'} width={14} height={14}/>
			)}
			<div>
				<p>{auditLog.user.email}</p>
			</div>
		</div>,

		<p>{format(new Date(auditLog.createdAt), "MMM dd, yyyy 'at' hh:mm aa z")}</p>,

		<Button
			style={ButtonStyles.BORDERED}
			icon={{
				id: 'arrowRight',
				width: 12,
				height: 10,
				align: ButtonsIconAlign.RIGHT,
				className: styles.dark,
			}}
			disableShadow={true}
			onClick={() => onViewDetailsClick(auditLog)}
		>
			View Details
		</Button>
	]);

	return <RedesignedTable headers={headers} rowsData={rowsData} />;
}

export default AuditLogTable
