import React, { CSSProperties, FC, ReactNode, useEffect, useRef, useState } from 'react'
import styles from './DateRangePicker.module.scss'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { format } from 'date-fns'

interface DateRangePickerProps {
	fromDate: Date | null
	setFromDate: (date: Date | null) => void
	toDate: Date | null
	setToDate: (date: Date | null) => void
	front?: ReactNode
	interfaceStyle?: CSSProperties
	disabled?: boolean;
	maxWidth?: number | string;
}
const DateRangePicker: FC<DateRangePickerProps> = ({
	fromDate,
	toDate,
	front,
	interfaceStyle,
	setFromDate,
	setToDate,
	disabled,
	maxWidth
}) => {
	const [isActive, setIsActive] = useState(false)
	const dateRangeRef = useRef<HTMLDivElement>(null)
	const [dates, setDates] = useState([
		{
			startDate: fromDate || new Date(),
			endDate: toDate || new Date(),
			key: 'selection',
		},
	])

	const handleClickOutside = (event: any) => {
		if (dateRangeRef.current && !dateRangeRef.current.contains(event.target as Node)) {
			setIsActive(false)
		}
	}

	useEffect(() => {
		if (isActive) {
			dateRangeRef.current?.classList.add(styles.active)
			setTimeout(() => {
				dateRangeRef.current?.classList.add(styles.visible)
			}, 10)
		} else {
			dateRangeRef.current?.classList.remove(styles.visible)
			setTimeout(() => {
				dateRangeRef.current?.classList.remove(styles.active)
			}, 200)
		}

		if (dateRangeRef.current) {
			document.addEventListener('mousedown', handleClickOutside)
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isActive])

	return (
		<div
			className={`${styles.dateRangePicker} ${disabled && styles.disabled}`}
			style={{
				maxWidth: maxWidth || '100%'
			}}
			ref={dateRangeRef}
			onClick={
				() => {
					!disabled && setIsActive(!isActive);
				}
			}
		>
			{front ? (
				front
			) : (
				<div className={styles.front}>
					<p>{format(dates[0].startDate, 'dd/MM/yyyy')}</p> <p> - </p>{' '}
					<p>{format(dates[0].endDate, 'dd/MM/yyyy')}</p>
				</div>
			)}

			<div
				className={`${styles.interface}`}
				onClick={event => {
					event.preventDefault()
					event.stopPropagation()
				}}
				style={interfaceStyle}
			>
				<DateRange
					rangeColors={['#FF3F19', '#FF3F19']}
					className={styles.dateRange}
					ranges={dates}
					onChange={rangeKeyDict => {
						setDates([rangeKeyDict.selection as any])
						setFromDate(rangeKeyDict.selection.startDate || null)
						setToDate(rangeKeyDict.selection.endDate || null)
					}}
					classNames={{
						nextButton: styles.dateRangeNextButton,
						prevButton: styles.dateRangePrevButton,
						nextPrevButton: styles.dateRangeNextPrevButton,
						dayPassive: styles.dateRangeDayPassive,
						weekDay: styles.dateRangeWeekDay,
						dayNumber: styles.dateRangeDayNumber,
						dateDisplayItem: styles.dateRangeDisplayItem,
						dateDisplayWrapper: styles.dateRangeDisplayWrapper,
					}}
				/>
			</div>
		</div>
	)
}

export default DateRangePicker
