import React, { FC, useEffect, useState } from 'react'
import styles from './TopProducts.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import FeedbackActionMenu from '../FeedbackActionMenu/FeedbackActionMenu'
import { DatePeriod } from '../../types/DatePeriod/DatePeriod'
import UpdatedOnDateBadge from '../UI/UpdatedOnDateBadge/UpdatedOnDateBadge'
import Tabs from '../UI/Tabs/Tabs'
import { TopProductChart } from '../../types/TopProductChart/TopProductChart'
import getSymbolFromCurrency from 'currency-symbol-map'
import { useAppContext } from '../../hooks/useAppContext'
import { formatNumberWithDecimalsAndCommas } from '../../helpers/formatNumberWithDecimalsAndCommas'
import {WidgetEmpty} from "../WidgetEmpty/WidgetEmpty";

enum TopProductsTabs {
	orders,
	revenue,
}

interface TrafficAcquisitionProps {
	datePeriod: DatePeriod
	data: TopProductChart[]
}

const TopProducts: FC<TrafficAcquisitionProps> = ({ datePeriod, data }) => {
	const [currentTab, setCurrentTab] = useState<TopProductsTabs>(TopProductsTabs.revenue)
	const [updatedDate, setUpdatedDate] = useState(new Date())
	const { shop } = useAppContext()

	const getDatePeriodInString = () => {
		switch (datePeriod) {
			case DatePeriod.allTime:
				return 'All time'
			case DatePeriod.lastDay:
				return 'Last 24 hours'
			case DatePeriod.lastMonth:
				return 'Last 30 days'
			case DatePeriod.lastWeek:
				return 'Last 7 days'
			case DatePeriod.lastYear:
				return 'Last 12 months'
		}
	}

	const sortedData = data
		.sort((a, b) => {
			if (currentTab === TopProductsTabs.revenue) {
				return b.totalRevenueDefaultCurrency - a.totalRevenueDefaultCurrency
			}
			return b.quantitySold - a.quantitySold
		})
		.slice(0, 5)

	useEffect(() => {
		setUpdatedDate(new Date())
	}, [data])

	return (
		<div className={styles.topProducts}>
			<header className={styles.header}>
				<h1>Top Products</h1>
				<FeedbackActionMenu />
			</header>

			{
				data.length ? (
					<>
						<header className={styles.subHeader}>
							<div>
								<time className={styles.time}>{getDatePeriodInString()}</time>
							</div>

							<UpdatedOnDateBadge date={updatedDate}/>
						</header>

						<div className={styles.tabsWrapper}>
							<Tabs
								tabs={[
									{
										label: 'Revenue',
										value: TopProductsTabs.revenue,
									},
									{
										label: 'Orders',
										value: TopProductsTabs.orders,
									},
								]}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
							/>
						</div>

						<div className={styles.statistics}>
							{sortedData.map(item => (
								<div className={styles.statisticsItem} id={item.productId.toString()}>
									<div className={styles.head}>
										<div className={styles.imageWrapper}></div>
										<p>{item.productName}</p>
									</div>
									{currentTab === TopProductsTabs.revenue ? (
										<span>
								{getSymbolFromCurrency(shop?.currency || 'USD')}
											{formatNumberWithDecimalsAndCommas(item.totalRevenueDefaultCurrency, 2)}
							</span>
									) : (
										<span>
								{item.quantitySold} order{item.quantitySold > 1 && 's'}
							</span>
									)}
								</div>
							))}
						</div>
					</>
				) : <WidgetEmpty />
			}
		</div>
	)
}

export default TopProducts
