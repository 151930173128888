import React, {FC} from "react";
import styles from './WidgetEmpty.module.scss';
import {SpriteIcon} from "../UI/SpriteIcon/SpriteIcon";

export const WidgetEmpty: FC = () => {
    return (
        <div className={styles.widgetEmpty}>
            <div className={styles.smiley}>
                <SpriteIcon iconId={'smiley-sad'} width={40} height={40}/>
            </div>
            <p>Nothing to see here yet.</p>
        </div>
    )
};