import React, {FC} from 'react';
import RedesignedTable from "../../RedesignedTable/RedesignedTable";
import {SpriteIcon} from '../../UI/SpriteIcon/SpriteIcon';
import OrdersStatusBadge from '../../UI/StatusBadges/OrdersStatusBadge';
import Dropdown from '../../UI/Dropdown/Dropdown';
import {OrderStatus} from '../../../types/OrderStatus/OrderStatus';
import styles from './OrdersHistoryTable.module.scss';
import defaultAvatarMock from '../../Tables/defaultAvatarMock.png';
import {Order} from "../../../types/Order/Order";
import {TelegramBot} from "../../../types/TelegramBot/TelegramBot";
import {MenuProps} from "antd";
import DropdownMenuItem from "../../UI/DropdownMenuItem/DropdownMenuItem";
import TableDropdown from "../../UI/TableDropdown/TableDropdown";
import PaymentTypeBadge from "../../PaymentTypeBadge/PaymentTypeBadge";
import TruncatedText from "../../TruncatedText/TruncatedText";
import UserDefaultAvatar from "../../UI/UserDefaultAvatar/UserDefaultAvatar";
import {on} from "process";
import {format} from "date-fns";

interface OrdersHistoryTableProps {
    orders: Order[];
    hoverEffect?: boolean;
    menuIsHidden?: boolean;
    onRowClick?: (orderId: string) => string;
}

const OrdersHistoryTable: FC<OrdersHistoryTableProps> = ({ orders, hoverEffect, menuIsHidden, onRowClick }) => {
    const formatOrderPrice = (currency: string, amount: number): string => {
        switch (currency) {
            case 'EUR':
                return `€${amount}`
            default:
                return `$${amount}`
        }
    }

    const transformDate = (isoDateString: string): Record<string, string> => {
        const date = new Date(isoDateString);

        const formatTime = (date: any): any => {
            const options = {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                timeZoneName: 'short'
            };
            return date.toLocaleTimeString('en-US', options);
        }

        return {
            formattedDate: format(date, 'MMM dd, yyyy'),
            formattedTime: formatTime(date)
        };
    }

    const headers = [
        { title: 'Customer Info' },
        { title: 'Status' },
        { title: 'Payment Type' },
        { title: 'Date & Time' },
        { title: 'Revenue' },
        { title: '' },
    ];

    const dropdownMenu = (): any => {
        const items: MenuProps['items'] = [
            {
                label: <DropdownMenuItem
                    text={'Edit Order'}
                    iconId={'edit-pen'}
                />,
                key: '0',
                onClick: () => {}
            },
            {
                label: <DropdownMenuItem
                    text={'Delete Order'}
                    iconId={'delete-bin'}
                />,
                key: '1',
                onClick: () => {}
            }
        ]

        return { items };
    };

    const rowsData = orders.map(order => [
        <div className={styles.orderDetails}>
            <div className={styles.orderDetailsAvatar}>
                <UserDefaultAvatar text={order.customerEmail} width={24} height={24} />
            </div>
            <div className={styles.orderDetailsText}>
                <span>{order.customerEmail}</span>
                <p>
                    {order.id}
                </p>
            </div>
        </div>,

        <div className={styles.status}>
            <OrdersStatusBadge orderStatus={order.status} />
        </div>,

        <div className={styles.paymentType}>
            <PaymentTypeBadge height={25} width={25} gateway={order.gateway} />
        </div>,

        <div className={styles.dateAndTime}>
            <span>{transformDate(order.createdAt).formattedDate}</span>
            <p>{transformDate(order.createdAt).formattedTime}</p>
        </div>,

        <div className={styles.value}>
            <span>{formatOrderPrice(order.endPrice.currency, order.endPrice.amount)}</span>
        </div>,

        !menuIsHidden && <TableDropdown menuItems={dropdownMenu()} />
    ]);

    return <RedesignedTable onRowClick={(index: number) => {
        if (onRowClick) {
            return onRowClick(orders[index].id)
        }
        return undefined;
    }} rowsHoverEffect={hoverEffect} headers={headers} rowsData={rowsData} />;
};

export default OrdersHistoryTable;