import React, {FC, useEffect, useRef, useState} from 'react';
import styles from './CustomMultiSelector.module.scss';
import {useOutsideClick} from "../../../hooks/useOutsideClick";
import {SpriteIcon} from "../SpriteIcon/SpriteIcon";
import SearchInput, {SearchInputStyles} from "../SearchInput/SearchInput";
import Checkbox from "../Checkbox/Checkbox";

const productsData = [
    {
        id: 1,
        name: 'Lamine Pro',
        price: '$400.99',
        paymentType: 'Every 1 year',
        variants: [
            { id: 'var1', name: 'Lamine Pro (Variant 1)', price: '$400.99' },
            { id: 'var2', name: 'Lamine Pro (Variant 2)', price: '$400.99' }
        ]
    },
    {
        id: 2,
        name: 'Xavi Free',
        price: '$22.99',
        paymentType: 'Every 1 month',
        variants: [
            { id: 'var3', name: 'Xavi Free (Variant 1)', price: '$22.99' },
            { id: 'var4', name: 'Xavi Free (Variant 2)', price: '$22.99' }
        ]
    },
    {
        id: 3,
        name: 'Lamine Pro',
        price: '$400.99',
        paymentType: 'Every 1 year',
        variants: [
            { id: 'var5', name: 'Lamine Pro (Variant 1)', price: '$400.99' },
            { id: 'var6', name: 'Lamine Pro (Variant 2)', price: '$400.99' }
        ]
    },
    {
        id: 4,
        name: 'Xavi Free',
        price: '$22.99',
        paymentType: 'Every 1 month',
        variants: [
            { id: 'var7', name: 'Xavi Free (Variant 1)', price: '$22.99' },
            { id: 'var8', name: 'Xavi Free (Variant 2)', price: '$22.99' }
        ]
    }
];

const productsVariants = productsData.flatMap(product => product.variants);

interface CustomMultiSelectorProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

const CustomMultiSelector: FC<CustomMultiSelectorProps> = ({ isOpen, setIsOpen }) => {
    const [expandedProducts, setExpandedProducts] = useState({});
    const [allProductsExpanded, setAllProductsExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const allVariantIds = productsData.flatMap(product => product.variants.map(v => v.id));
    const [selectedProducts, setSelectedProducts] = useState<string[]>([...allVariantIds]);
    const [productsSelectorFaceValue, setProductsSelectorFaceValue] = useState<string | string[]>('All products')

    const dropdownRef = useRef(null);

    useOutsideClick(dropdownRef, () => setIsOpen(false));

    const filteredProducts = productsData.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSelectProduct = (productId: number, variantId?: string | undefined) => {
        if (variantId) {
            if (selectedProducts.includes(variantId)) {
                setSelectedProducts(selectedProducts.filter(id => id !== variantId));
            } else {
                setSelectedProducts([...selectedProducts, variantId]);
            }
        } else {
            const product = productsData.find(p => p.id === productId);
            const variantIds = product ? product.variants.map(v => v.id) : [];
            const areAllSelected = variantIds?.every(id => selectedProducts.includes(id));

            if (areAllSelected) {
                setSelectedProducts(selectedProducts.filter(id => !variantIds?.includes(id)));
            } else {
                setSelectedProducts([...selectedProducts, ...variantIds.filter(id => !selectedProducts.includes(id))]);
            }
        }
    };

    const handleSelectAllProducts = () => {
        allVariantIds.every(id => selectedProducts.includes(id))
            ? setSelectedProducts([])
            :  setSelectedProducts(allVariantIds);
    };

    const handleExpandProduct = (productId: number) => {
        setExpandedProducts({
            ...expandedProducts,
            [productId]:
                // @ts-ignore
                !expandedProducts[productId],
        });
    };

    const handleExpandAllProducts = () => {
        setAllProductsExpanded(!allProductsExpanded);
    };

    const handleDeleteTag = (tagName: string) => {
        const tagId = productsVariants.find(v => v.name === tagName)?.id
        setSelectedProducts(selectedProducts.filter(tag => tag !== tagId));
    };

    useEffect(() => {
        if (selectedProducts.length === allVariantIds.length) {
            setProductsSelectorFaceValue('All products')
        } else {
            const productsNames: string[] = [];
            selectedProducts.forEach(variantId => {
                const variantIndex = productsVariants.findIndex(value => value.id === variantId);

                if (variantIndex !== -1) {
                    productsNames.push(productsVariants[variantIndex].name);
                }
            });
            setProductsSelectorFaceValue(productsNames)
        }
    }, [selectedProducts]);

    return (
        <div className={styles.container} ref={dropdownRef}>
            <div className={styles.dropdownHeader} onClick={() => setIsOpen(!isOpen)}>
                {
                    typeof productsSelectorFaceValue === 'string'
                        ? <span>All Products</span>
                        : productsSelectorFaceValue.length
                            ? (
                                <div className={styles.dropdownHeaderContainer}>
                                    <div>
                                        {productsSelectorFaceValue[0]?.slice(0, 5) + `${productsSelectorFaceValue[0]?.length > 6 && '...'}`}
                                        <button onClick={(event) => {
                                            event.preventDefault();
                                            handleDeleteTag(productsSelectorFaceValue[0])
                                        }}>
                                            <SpriteIcon iconId={'crossMark'} width={12} height={12} />
                                        </button>
                                    </div>
                                    {
                                        productsSelectorFaceValue.length > 1
                                        && <div>
                                            + {' ' + (productsSelectorFaceValue.length - 1) + ' ...'}
                                        </div>
                                    }
                                </div>
                            )
                        : <span>Select products</span>
                }
                <SpriteIcon rotate={isOpen ? 180 : undefined} iconId="arrowDown2" width={14} height={14} />
            </div>

            {isOpen && (
                <div className={styles.productSelectorDropdown}>
                    <div className={styles.productSelector}>
                        <div className={styles.header}>
                            <SearchInput
                                value={searchTerm}
                                setValue={setSearchTerm}
                                placeholder="Search for products..."
                                style={SearchInputStyles.bordered}
                                width="unset"
                                borderRadius={100}
                                customClass={styles.headerInput}
                            />
                            <span>Selected: {selectedProducts.length}</span>
                        </div>

                        <div className={styles.allProductsSelector} onClick={handleExpandAllProducts}>
                            <SpriteIcon
                                rotate={allProductsExpanded ? 180 : undefined}
                                iconId="arrowDown2"
                                width={14}
                                height={14}
                                className={styles.productArrow}
                            />
                            <Checkbox
                                id="all-products"
                                checked={productsData.every(product =>
                                    product.variants.every(v => selectedProducts.includes(v.id))
                                )}
                                setChecked={handleSelectAllProducts}
                                customClassname={styles.checkbox}
                            />
                            <div className={styles.textContainer}>
                                <span>All Products</span>
                            </div>
                        </div>

                        <div className={styles.productList}>
                            {allProductsExpanded && (
                                <>
                                    {filteredProducts.map(product => (
                                        <div key={product.id} className={styles.product}>
                                            <div
                                                className={styles.productHeader}
                                                onClick={() => handleExpandProduct(product.id)}
                                            >
                                                <SpriteIcon
                                                    rotate={
                                                        // @ts-ignore
                                                        expandedProducts[product.id] ? 180 : undefined
                                                    }
                                                    iconId="arrowDown2"
                                                    width={14}
                                                    height={14}
                                                    className={styles.productArrow}
                                                />
                                                <Checkbox
                                                    id={product.id.toString()}
                                                    checked={product.variants.every(v =>
                                                        selectedProducts.includes(v.id)
                                                    )}
                                                    setChecked={() => handleSelectProduct(product.id)}
                                                    customClassname={styles.checkbox}
                                                />
                                                <div className={styles.textContainer}>
                                                    <span>{product.name}</span>
                                                    <span>ID - {product.id}</span>
                                                </div>
                                            </div>

                                            {/* Expand Product Variants */}
                                            {
                                                // @ts-ignore
                                                expandedProducts[product.id] && (
                                                    <div className={styles.variants}>
                                                        {product.variants.map(variant => (
                                                            <div key={variant.id} className={styles.variant}>
                                                                <Checkbox
                                                                    id={variant.id.toString()}
                                                                    checked={selectedProducts.includes(variant.id)}
                                                                    setChecked={() => handleSelectProduct(product.id, variant.id)}
                                                                    customClassname={styles.checkbox}
                                                                />
                                                                <div className={styles.variant__textContainer}>
                                                                    <span>{variant.name}</span>
                                                                    <span>{variant.price}</span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomMultiSelector;