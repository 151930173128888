import React, {FC, useEffect, useRef, useState} from "react";
import {StatisticsData} from "../../types/StatisticsData/StatisticsData";
import styles from './AnalyticsGraphs.module.scss';
import FeedbackActionMenu from "../FeedbackActionMenu/FeedbackActionMenu";
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    CartesianGrid,
    Legend, Line, LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {format} from "date-fns";
import {WidgetEmpty} from "../WidgetEmpty/WidgetEmpty";
import {useHandledData} from "../../hooks/useHandledData";
import {GraphWrapper} from "../GraphWrapper/GraphWrapper";
import {useHandledDataWithCompare} from "../../hooks/useHandledDataWithCompare";
import {useGraphLegend} from "../../hooks/useGraphLegend";

interface OrdersAnalyticsProps {
    data: StatisticsData[]
    dateRange: {
        fromDate: Date | null
        toDate: Date | null
    }
    compareDateRange?: {
        fromDate: Date | null
        toDate: Date | null
    }
    shouldCompare?: boolean
    compareData?: StatisticsData[]
}

const OrdersAnalytics: FC<OrdersAnalyticsProps> = ({ data, dateRange, compareDateRange, shouldCompare, compareData }) => {
    const { handledData, totalValueRef, focusValue, setFocusValue } = useHandledData(data, dateRange);
    const maxCountLength = data.length ? Math.max(...data.map(d => d.count)).toString().length : 0;

    const { fullData, compareCounts } = useHandledDataWithCompare(
        compareData?? [], dateRange, handledData, shouldCompare ?? false
    );

    const legendSettings = useGraphLegend(dateRange, compareDateRange);

    return (
        <GraphWrapper title={'Orders'}>
            {
                data.length ? (
                    <>
                        <header className={styles.subHeader}>
                            <p>{totalValueRef.current}</p>
                        </header>
                        {
                            !shouldCompare ? (
                                <div className={styles.graph}>
                                    <ResponsiveContainer width={'100%'} height={300}>
                                        <BarChart
                                            data={handledData}
                                            onMouseLeave={() => {
                                                setFocusValue(totalValueRef.current)
                                            }}
                                            margin={{
                                                top: 5,
                                                right: 0,
                                                left: maxCountLength > 1 ? maxCountLength * 5 : 0,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3"/>
                                            <XAxis dataKey={'date'}/>
                                            <YAxis
                                                allowDataOverflow={false}
                                                type={'number'}
                                                dataKey={'count'}
                                                tick={{fontSize: 'var(--small)', width: 100}}
                                                orientation={'right'}
                                            />
                                            <Tooltip
                                                labelStyle={{
                                                    color: 'var(--dark_color)',
                                                }}
                                                contentStyle={{
                                                    backgroundColor: 'var(--light_gray)',
                                                    borderRadius: 12,
                                                    borderColor: 'none',
                                                }}
                                                formatter={(value) => {
                                                    setFocusValue(value as number)
                                                    return value
                                                }}
                                            />
                                            <Bar
                                                dataKey={'count'}
                                                stroke='#FF3F19'
                                                fill='var(--primary_color)'
                                            />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            ) : (
                                <div className={styles.graph}>
                                    <ResponsiveContainer width={'100%'} height={300}>
                                        <LineChart
                                            data={fullData}
                                            onMouseLeave={() => {
                                                setFocusValue(totalValueRef.current)
                                            }}
                                            margin={{
                                                top: 5,
                                                right: 0,
                                                left: maxCountLength > 1 ? maxCountLength * 5 : 0,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3"/>
                                            <XAxis dataKey={'date'}/>
                                            <YAxis
                                                allowDataOverflow={false}
                                                type={'number'}
                                                dataKey={compareCounts() ? 'count' : 'compareCount'}
                                                tick={{fontSize: 'var(--small)', width: 100}}
                                                orientation={'right'}
                                                padding={{ bottom: 12 }}
                                            />
                                            <Tooltip
                                                labelStyle={{
                                                    color: 'var(--dark_color)',
                                                }}
                                                contentStyle={{
                                                    backgroundColor: 'var(--light_gray)',
                                                    borderRadius: 12,
                                                    borderColor: 'none',
                                                }}
                                                formatter={(value) => {
                                                    setFocusValue(value as number)
                                                    return value
                                                }}
                                            />
                                            <Line
                                                dataKey={'count'}
                                                stroke='#FF3F19'
                                                fill='var(--primary_color)'
                                                strokeWidth={3}
                                            />
                                            <Line
                                                dataKey={'compareCount'}
                                                stroke='var(--graph_blue)'
                                                fill='var(--graph_blue)'
                                                strokeWidth={3}
                                            />
                                            <Legend {...legendSettings} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            )
                        }
                    </>
                ) : <WidgetEmpty/>
            }
        </GraphWrapper>
    )
};

export default OrdersAnalytics;