import { Formatter, HorizontalAlignmentType, IconType } from "recharts/types/component/DefaultLegendContent";
import { useMemo } from "react";
import {format} from "date-fns";

interface LegendSettings {
    formatter: Formatter | undefined;
    align: HorizontalAlignmentType | undefined;
    iconType: IconType | undefined;
    iconSize: number | undefined;
}

interface dateRange {
    fromDate: Date | null;
    toDate: Date | null;
}

export const useGraphLegend = (currentDateRange: dateRange, compareDateRange?: dateRange): LegendSettings => {
    const thisPeriodDateRange = {
        from: format(currentDateRange.fromDate ?? new Date(), 'MMM dd, yyyy'),
        to: format(currentDateRange.toDate ?? new Date(), 'MMM dd, yyyy'),
    };

    const previousPeriodDateRange = compareDateRange
        ? {
            from: format(compareDateRange?.fromDate ?? new Date(), 'MMM dd, yyyy'),
            to: format(compareDateRange?.toDate ?? new Date(), 'MMM dd, yyyy'),
        }
        : undefined;

    return useMemo(() => ({
        formatter: previousPeriodDateRange
            ? (value: string) => (
                value === 'count'
                    ? `This period (${thisPeriodDateRange.from} - ${thisPeriodDateRange.to})`
                    : `Previous (${previousPeriodDateRange.from} - ${previousPeriodDateRange.to})`
            )
            : undefined,
        align: 'left' as HorizontalAlignmentType,
        iconType: 'circle' as IconType,
        iconSize: 10,
    }), []);
};
