import styles from './StatusBadges.module.scss';
import React, {FC} from "react";
import {SpriteIcon} from "../SpriteIcon/SpriteIcon";
import {TelegramBotStatus} from "../../../types/TelegramBotStatus/TelegramBotStatus";

interface TelegramBotStatusBadgeProps {
    botStatus: TelegramBotStatus;
    error?: string;
}

const TelegramBotStatusBadge: FC<TelegramBotStatusBadgeProps> = ({ botStatus, error }) => {
    const telegramBotStatusData = {
        [TelegramBotStatus.ONLINE]: {
            name: 'Online',
            className: 'green',
            iconId: 'checkedCircle'
        },
        [TelegramBotStatus.OFFLINE]: {
            name: 'Offline',
            className: 'red',
            iconId: 'xMarkCircle'
        },
    }

    return (
        <div
            className={`${styles.statusBadge} ${styles.subscriptionStatusBadge} ${styles[telegramBotStatusData[botStatus].className]}`}
            data-tooltip-id={error && 'my-tooltip'}
            data-tooltip-content={`${error && `Error: ${error}`}`}
        >
            <SpriteIcon iconId={telegramBotStatusData[botStatus].iconId} width={16} height={16} />
            {telegramBotStatusData[botStatus].name}
        </div>
    )
}

export default TelegramBotStatusBadge