import React, {useRef, useState} from 'react'
import styles from './ShopProfile.module.scss'
import { SpriteIcon } from '../UI/SpriteIcon/SpriteIcon'
import BackgroundOverlay from '../UI/BackgroundOverlay/BackgroundOverlay'
import { useAppContext } from '../../hooks/useAppContext'
import { useAPI } from '../../hooks/useAPI'
import { UserService } from '../../API/UserService'
import { NavLink } from 'react-router-dom'
import {useOutsideClick} from "../../hooks/useOutsideClick";

const ShopProfile = () => {
	const [isActive, setIsActive] = useState(false)
	const { shop: currentShop, user, shopDomain } = useAppContext()
	const menuRef = useRef<HTMLDivElement>(null);
	useOutsideClick(menuRef, () => { setIsActive(false) });

	const [, , logout] = useAPI(() => UserService.logout(), false, 'Successfully signed out', {
		onSuccess: () => {
			localStorage.removeItem('accessToken')
			localStorage.removeItem('shopId')
			window.location.pathname = '/auth/sign-in'
		},
	})

	const closeShopProfile = () => {
		setIsActive(false)
	}

	return (
		<>
			<div className={`${styles.shopProfile} ${isActive && styles.active}`}>
				<div className={`${styles.menu} ${isActive && styles.active}`} ref={menuRef}>
					<a
						href={user?.isEmailVerified === true ? shopDomain : 'javascript:void(0)'}
						target={'_blank'}
						className={`${styles.menuItem} ${user?.isEmailVerified === false && styles.isDisabled}`}
						onClick={() => {user?.isEmailVerified === true && closeShopProfile()}}
					>
						<SpriteIcon
							className={styles.selectIcon}
							iconId={'blankWindow'}
							width={13}
							height={13}
						/>
						<p>View your website</p>
					</a>
					<NavLink to={user?.isEmailVerified === true ? '/settings/general': ''} className={`${styles.menuItem} ${user?.isEmailVerified === false && styles.isDisabled}`} onClick={() => {user?.isEmailVerified === true && closeShopProfile()}}>
						<SpriteIcon className={styles.selectIcon} iconId={'gear'} width={13} height={13} />
						<p>Edit settings</p>
					</NavLink>
					<NavLink to={user?.isEmailVerified === true ? '/settings/billing' : ''} className={`${styles.menuItem} ${user?.isEmailVerified === false && styles.isDisabled}`} onClick={() => {user?.isEmailVerified === true && closeShopProfile()}}>
						<SpriteIcon className={styles.selectIcon} iconId={'person'} width={13} height={13} />
						<p>Manage subscription</p>
					</NavLink>
					<div className={styles.menuItem} onClick={logout}>
						<SpriteIcon className={styles.selectIcon} iconId={'exit'} width={13} height={13} />
						<p>Sign out</p>
					</div>
				</div>

				<div className={styles.front} onClick={() => setIsActive(!isActive)}>
					<div className={styles.iconWrapper}>
						<SpriteIcon iconId={'person2'} width={13} height={13} />
					</div>
					<p>{user?.email}</p>
				</div>
			</div>
		</>
	)
}

export default ShopProfile
