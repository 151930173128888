import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import styles from './ActionMenu.module.scss'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import { SpriteIcon } from '../SpriteIcon/SpriteIcon'
import { ActionMenuOption } from '../../../types/ActionMenuOption/ActionMenuOption'

export enum ActionMenuStyles {
	secondary = 'secondary',
	light = 'light',
	third = 'third',
}

interface ActionMenuProps {
	actionButtons: ActionMenuOption[]
	isActive: boolean
	setIsActive: (value: boolean) => void
	style?: ActionMenuStyles
}

const ActionMenu: FC<ActionMenuProps> = ({ actionButtons, isActive, setIsActive, style }) => {
	const actionMenuRef = useRef<HTMLDivElement>(null)
	useOutsideClick(
		actionMenuRef,
		() => {
			setIsActive(false)
		},
		!isActive
	)

	const onClickHandler = (event: React.MouseEvent) => {
		event.stopPropagation()
		event.preventDefault()
		setIsActive(!isActive)
	}

	useEffect(() => {
		if (isActive) {
			actionMenuRef.current?.classList.add(styles.active)

			setTimeout(() => {
				actionMenuRef.current?.classList.add(styles.visible)
			}, 10)
		} else {
			actionMenuRef.current?.classList.remove(styles.visible)

			setTimeout(() => {
				actionMenuRef.current?.classList.remove(styles.active)
			}, 300)
		}
	}, [isActive])

	return (
		<div className={styles.actionMenuWrapper}>
			<div
				className={`${styles.ellipsisWrapper} ${style && styles[style]} ${
					isActive && styles.active
				}`}
				onClick={onClickHandler}
				onMouseDown={event => event.stopPropagation()}
			>
				<SpriteIcon iconId={'ellipsis'} width={15} height={6} />
			</div>
			<div className={`${styles.actionMenu} ${style && styles[style]}`} ref={actionMenuRef}>
				{actionButtons.map(button => (
					<div
						key={button.title}
						className={styles.actionButton}
						onClick={event => {
							event.stopPropagation()
							event.preventDefault()
							button.onClick()
						}}
					>
						<SpriteIcon
							iconId={button.icon.id}
							width={button.icon.width}
							height={button.icon.height}
						/>
						<p>{button.title}</p>
					</div>
				))}
			</div>
		</div>
	)
}

export default ActionMenu
