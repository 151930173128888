import React, {FC, useEffect, useRef, useState} from "react";
import {GraphWrapper} from "../GraphWrapper/GraphWrapper";
import styles from "./AnalyticsGraphs.module.scss";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {WidgetEmpty} from "../WidgetEmpty/WidgetEmpty";
import {useGraphLegend} from "../../hooks/useGraphLegend";

interface TopPromotionsCostsAnalyticsProps {
    data: {
        title: string;
        count: number;
    }[]
    dateRange: {
        fromDate: Date | null
        toDate: Date | null
    }
    compareDateRange?: {
        fromDate: Date | null
        toDate: Date | null
    }
    shouldCompare?: boolean
    compareData?: {
        title: string;
        count: number;
    }[]
}

const TopPromotionsCostsAnalytics:FC<TopPromotionsCostsAnalyticsProps> = ({ data, dateRange, compareDateRange, shouldCompare, compareData }) => {
    const totalValueRef = useRef(0);
    const [, setCount] = useState(0);

    useEffect(() => {
        totalValueRef.current = data.map(data => data.count).reduce((a, b) => a + b, 0);
        setCount(totalValueRef.current);
    }, [data]);

    const maxCountLength = data.length ? Math.max(...data.map(d => d.count)).toString().length : 0;

    const fullData: {title: string, count?: number, compareCount?: number}[] = [...data];

    const legendSettings = useGraphLegend(dateRange, compareDateRange);

    useEffect(() => {
        if (shouldCompare) {
            compareData?.forEach((item) => {
                const index = fullData.findIndex(item => item.title === item.title);
                if (index !== -1) {
                    fullData[index].compareCount = item.count;
                } else {
                    fullData.push({
                        title: item.title,
                        compareCount: item.count
                    });
                }
            });
        }
    }, [compareData]);

    return (
        <GraphWrapper title={'Top promotions costs'}>
            {
                data.length ? (
                    <>
                        <header className={styles.subHeader}>
                            <p>${totalValueRef.current} Total</p>
                        </header>
                        <div className={styles.graph}>
                            <ResponsiveContainer width={'90%'} height={300}>
                                <BarChart
                                    data={shouldCompare ? fullData : data}
                                    margin={{
                                        top: 5,
                                        right: 0,
                                        left: 50,
                                        bottom: 5,
                                    }}
                                    layout={'vertical'}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey={'count'} type={'number'}/>
                                    <YAxis
                                        allowDataOverflow={false}
                                        type={'category'}
                                        dataKey={'title'}
                                        tick={{fontSize: 'var(--small)', width: 100}}
                                        orientation={'left'}
                                    />
                                    <Tooltip
                                        labelStyle={{
                                            color: 'var(--dark_color)',
                                        }}
                                        contentStyle={{
                                            backgroundColor: 'var(--light_gray)',
                                            borderRadius: 12,
                                            borderColor: 'none',
                                        }}
                                        formatter={(value) => {
                                            return value
                                        }}
                                    />
                                    <Bar
                                        dataKey={'count'}
                                        stroke='#FF3F19'
                                        fill='var(--primary_color)'
                                    />
                                    {
                                        shouldCompare && <Bar
                                            dataKey={'compareCount'}
                                            stroke='var(--graph_blue)'
                                            fill='var(--graph_blue)'
                                        />
                                    }
                                    {
                                        shouldCompare && <Legend {...legendSettings} />
                                    }
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </>
                ) : <WidgetEmpty/>
            }
        </GraphWrapper>
    )
};

export default TopPromotionsCostsAnalytics;