import React, { FC } from 'react';
import styles from './TelegramBotsTable.module.scss';
import defaultAvatarMock from '../defaultAvatarMock.png';
import {TelegramBotStatus} from "../../../types/TelegramBotStatus/TelegramBotStatus";
import TelegramBotStatusBadge from "../../UI/StatusBadges/TelegramBotStatusBadge";
import {TelegramBot} from "../../../types/TelegramBot/TelegramBot";
import RedesignedTable from "../../RedesignedTable/RedesignedTable";
import {MenuProps} from "antd";
import DropdownMenuItem from "../../UI/DropdownMenuItem/DropdownMenuItem";
import TableDropdown from "../../UI/TableDropdown/TableDropdown";
import UserDefaultAvatar from "../../UI/UserDefaultAvatar/UserDefaultAvatar";

interface TelegramBotsTableProps {
    bots: TelegramBot[];
    onDeleteBot: (botId: string) => void;
    onEditBot: (data: {
        botId: string,
        botName: string,
        botToken: string,
        supportUsername: string
    }) => void;
}

interface TelegramBotsTableItemProps {
    bot: TelegramBot;
    onDeleteBot: (botId: string) => void;
    onEditBot: (data: {
        botId: string,
        botName: string,
        botToken: string,
        supportUsername: string
    }) => void;
}

const TelegramBotsTable: FC<TelegramBotsTableProps> = ({
    bots,
    onDeleteBot,
    onEditBot
 }) => {
    const checkSubscriptionStatus = (status: string): TelegramBotStatus => {
        switch (status) {
            case 'Online':
                return TelegramBotStatus.ONLINE;
            default:
                return TelegramBotStatus.OFFLINE;
        }
    };

    const checkIfEnoughSpaceUnder = (index: any): boolean => {
        return (index !== bots.length - 2 && index !== bots.length - 1)
    };

    const headers = [
        { title: 'Name' },
        { title: 'Status' },
        { title: 'Support Username' },
        { title: '' },
    ];

    const dropdownMenu = (bot: TelegramBot): any => {
        const items: MenuProps['items'] = [
            {
                label: <DropdownMenuItem
                    text={'Edit Bot'}
                    iconId={'edit-pen'}
                />,
                key: '0',
                onClick: () => {
                    bot.id && onEditBot({
                        botId: bot.id,
                        botName: bot.name,
                        botToken: bot.token,
                        supportUsername: bot.supportUsername
                    });
                }
            },
            {
                label: <DropdownMenuItem
                    text={'Delete Bot'}
                    iconId={'delete-bin'}
                />,
                key: '1',
                onClick: () => {
                    onDeleteBot(bot.id ?? '')
                }
            }
        ]

        return { items };
    };

    const rowsData = bots.map((bot, index) => [
        <div className={styles.nameInfo}>
            {bot.name}
        </div>,

        <div className={styles.status}>
            <TelegramBotStatusBadge
                error={bot.initializationError}
                botStatus={checkSubscriptionStatus(bot.initializationError ? 'Offline' : 'Online')}/>
        </div>,

        <div className={styles.supportInfo}>
            <UserDefaultAvatar text={bot.supportUsername} width={24} height={24} />
            <span className={styles.supportInfoUsername}>{bot.supportUsername}</span>
        </div>,

        <TableDropdown menuItems={dropdownMenu(bot)} />
    ]);

    return <RedesignedTable headers={headers} rowsData={rowsData} />;
};

export default TelegramBotsTable;