import {FC, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageContainer from "../../components/UI/PageContainer/PageContainer";
import PageHeader from "../../components/PageHeader/PageHeader";
import styles from './AdvancedAnalyticsReportPage.module.scss';
import {
    AdvancedAnalyticsReportPageLoading
} from "./AdvancedAnalyticsReportPageLoading/AdvancedAnalyticsReportPageLoading";
import {useAPI} from "../../hooks/useAPI";
import {ReportsService} from "../../API/ReportsService";
import {useAppContext} from "../../hooks/useAppContext";
import PageLoading from "../../components/UI/PageLoading/PageLoading";
import {useAfterMountEffect} from "../../hooks/useAfterMountEffect";
import {
    AnalyticsReportDetails,
    PromotionCosts,
    Refund,
    Revenue
} from "../../types/AnalyticsReportDetails/AnalyticsReportDetails";
import {format} from "date-fns";
import OrdersAnalytics from "../../components/AnalyticsGraphs/OrdersAnalytics";
import RevenueAnalytics from "../../components/AnalyticsGraphs/RevenueAnalytics";
import {StatisticsData} from "../../types/StatisticsData/StatisticsData";
import QuantityAnalytics from "../../components/AnalyticsGraphs/QuantityAnalytics";
import NetAnalytics from "../../components/AnalyticsGraphs/NetAnalytics";
import RefundsAnalytics from "../../components/AnalyticsGraphs/RefundsAnalytics";
import TopPromotionsCostsAnalytics from "../../components/AnalyticsGraphs/TopPromotionsCostsAnalytics";
import TopPaymentMethodsAnalytics from "../../components/AnalyticsGraphs/TopPaymentMethodsAnalytics";
import TopProductsAnalytics from "../../components/AnalyticsGraphs/TopProductsAnalytics";
import TopCountriesAnalytics from "../../components/AnalyticsGraphs/TopCountriesAnalytics";

const AdvancedAnalyticsReportPage: FC = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState<boolean | null>(true);
    const { shop } = useAppContext();

    const [getReportIsLoading, report, getReport] = useAPI<AnalyticsReportDetails>(
        () => ReportsService.getReport(shop?.id || '', id || ''),
        false,
        undefined,
        {
            onSuccess: () => {
                setIsLoading(report?.status === 'CREATING')
            }
        }
    );

    const shouldCompare:boolean = !!report?.productTypeReport.comparePeriod;

    const getLocaleDateString = (dt: string | Date): string => new Date(dt)
        .toLocaleDateString('ru-RU', { timeZone: 'UTC' })

    const compareDate = (d1: string | Date, d2: string | Date) => {
        return getLocaleDateString(d1) === getLocaleDateString(d2)
    }

    const timeSpan = new Date(report?.toDate ?? '').getTime()
        - new Date(report?.fromDate ?? '').getTime()
        + 86400000;

    const getOrdersDataFromRevenueData = (revenueData: Revenue[], isCompareData?: boolean): {count: number, date: Date}[] => {
        const ordersData: {count: number, date: Date}[] = [];

        !isCompareData
            ? revenueData.forEach((order) => {
                const orderIndex = ordersData.findIndex(el => {
                    return compareDate(order.createdAt, el.date)
                })

                if (orderIndex !== -1) {
                    ordersData[orderIndex].count += 1
                } else {
                    ordersData.push({
                        count: 1,
                        date: new Date(order.createdAt)
                    })
                }
            })
            : revenueData.forEach((order) => {
                const orderIndex = ordersData.findIndex(el => {
                    return compareDate(new Date(new Date(order.createdAt).getTime() + timeSpan), el.date)
                })

                if (orderIndex !== -1) {
                    ordersData[orderIndex].count += 1
                } else {
                    ordersData.push({
                        count: 1,
                        date: new Date(new Date(order.createdAt).getTime() + timeSpan)
                    })
                }
            });

        return ordersData;
    };

    const getFormattedRevenueData = (rawRevenueData: Revenue[], isCompare?: boolean): StatisticsData[] => {
        const revenueData: StatisticsData[] = [];

        !isCompare
            ? (
                rawRevenueData.forEach((revenue) => {
                    const operationIndex = revenueData.findIndex(el => {
                        return compareDate(revenue.createdAt, el.date)
                    });

                    if (operationIndex !== -1) {
                        revenueData[operationIndex].count += revenue.endPriceDefaultCurrency
                    } else {
                        revenueData.push({
                            count: revenue.endPriceDefaultCurrency,
                            date: new Date(revenue.createdAt)
                        })
                    }
                })
            ) : (
                rawRevenueData.forEach((revenue) => {
                    const operationIndex = revenueData.findIndex(el => {
                        return compareDate(new Date(new Date(revenue.createdAt).getTime() + timeSpan), el.date)
                    });

                    if (operationIndex !== -1) {
                        revenueData[operationIndex].count += revenue.endPriceDefaultCurrency
                    } else {
                        revenueData.push({
                            count: revenue.endPriceDefaultCurrency,
                            date: new Date(new Date(revenue.createdAt).getTime() + timeSpan)
                        })
                    }
                })
            )

        return revenueData;
    };

    const getQuantityDataFromRevenueData = (revenueData: Revenue[], isCompare?: boolean): {count: number, date: Date}[] => {
        const quantityData: {count: number, date: Date}[] = [];

        !isCompare
            ? revenueData.forEach((order) => {
                    const orderIndex = quantityData.findIndex(el => {
                        return compareDate(order.createdAt, el.date)
                    })

                    if (orderIndex !== -1) {
                        quantityData[orderIndex].count += order.quantity
                    } else {
                        quantityData.push({
                            count: order.quantity,
                            date: new Date(order.createdAt)
                        })
                    }
                })
            : revenueData.forEach((order) => {
                const orderIndex = quantityData.findIndex(el => {
                    return compareDate(new Date(new Date(order.createdAt).getTime() + timeSpan), el.date)
                })

                if (orderIndex !== -1) {
                    quantityData[orderIndex].count += order.quantity
                } else {
                    quantityData.push({
                        count: order.quantity,
                        date: new Date(new Date(order.createdAt).getTime() + timeSpan)
                    })
                }
            })

        return quantityData;
    };

    const getNetData = (rawRevenueData: Revenue[], isCompare?: boolean): StatisticsData[] => {
        const netData: StatisticsData[] = [];

        !isCompare
            ? rawRevenueData.forEach((revenue) => {
                    const operationIndex = netData.findIndex(el => {
                        return compareDate(revenue.createdAt, el.date)
                    });

                    if (operationIndex !== -1) {
                        netData[operationIndex].count += revenue.netPriceDefaultCurrency
                    } else {
                        netData.push({
                            count: revenue.endPriceDefaultCurrency,
                            date: new Date(revenue.createdAt)
                        })
                    }
                })
            : rawRevenueData.forEach((revenue) => {
                const operationIndex = netData.findIndex(el => {
                    return compareDate(new Date(new Date(revenue.createdAt).getTime() + timeSpan), el.date)
                });

                if (operationIndex !== -1) {
                    netData[operationIndex].count += revenue.netPriceDefaultCurrency
                } else {
                    netData.push({
                        count: revenue.endPriceDefaultCurrency,
                        date: new Date(new Date(revenue.createdAt).getTime() + timeSpan)
                    })
                }
            });


        return netData;
    };

    const getFormattedRefundsData = (rawRefundsData: Refund[], isCompare?: boolean): StatisticsData[] => {
        const refundsData: StatisticsData[] = [];

        isCompare
            ? rawRefundsData.forEach((refund) => {
                const refundIndex = refundsData.findIndex(el => {
                    return compareDate(refund.createdAt, el.date)
                });

                if (refundIndex!== -1) {
                    refundsData[refundIndex].count += 1
                } else {
                    refundsData.push({
                        count: 1,
                        date: new Date(refund.createdAt)
                    })
                }
            })
            : rawRefundsData.forEach((refund) => {
                const refundIndex = refundsData.findIndex(el => {
                    return compareDate(new Date(new Date(refund.createdAt).getTime() + timeSpan), el.date)
                });

                if (refundIndex!== -1) {
                    refundsData[refundIndex].count += 1
                } else {
                    refundsData.push({
                        count: 1,
                        date: new Date(new Date(refund.createdAt).getTime() + timeSpan)
                    })
                }
            });

        return refundsData;
    };

    const getPromotionsCostsData = (rawPromotionsData: PromotionCosts) => {
        const promotionsCostsData: { title: string; count: number }[] = [];

        Object.keys(rawPromotionsData.costs).forEach((key) => {
            promotionsCostsData.push({
                title: key,
                // @ts-ignore
                count: rawPromotionsData.costs[key]
            });
        });

        return promotionsCostsData
    };

    const getPaymentMethodsData = (rawRevenueData: Revenue[]) => {
        const paymentMethodsData: { title: string; count: number }[] = [];

        rawRevenueData.forEach((revenue) => {
            const paymentMethodIndex = paymentMethodsData.findIndex(el => {
                return el.title === revenue.gateway
            });

            if (paymentMethodIndex!== -1) {
                paymentMethodsData[paymentMethodIndex].count += revenue.endPriceDefaultCurrency
            } else {
                paymentMethodsData.push({
                    title: revenue.gateway,
                    count: 1
                })
            }
        });

        return paymentMethodsData;
    };

    useEffect(() => {
        shop && getReport();
    }, [shop, id]);

    useAfterMountEffect(() => {
        if (report?.status === 'CREATING') {
            setIsLoading(true);
            setTimeout(() => {
                getReport();
            }, 1000);
        } else if (report?.status === 'READY') {
            setIsLoading(false);
        }
    }, [isLoading, report]);

    if (getReportIsLoading) {
        return <PageLoading />
    }

    if (isLoading || !report) {
        return <AdvancedAnalyticsReportPageLoading />
    }

    return (
        <PageWrapper>
            <PageContainer>
                <PageHeader title={'Created Advanced Analytics'} isColumn>
                    <p className={styles.headerTitle}>
                        The data is current as of the date created -
                        {' ' + format(new Date(report?.createdAt), 'MMM dd, yyyy')}
                        {' ' + 'at'}
                        {' ' + format(new Date(report.createdAt), 'hh:hh aa')}
                    </p>
                </PageHeader>

                <div className={styles.reportPage}>
                    <OrdersAnalytics
                        data={getOrdersDataFromRevenueData(report.productTypeReport.currentPeriod.revenue, false)}
                        dateRange={{
                            fromDate: new Date(report.fromDate),
                            toDate: new Date(report.toDate)
                        }}
                        compareDateRange={
                            shouldCompare
                                ? {
                                    fromDate: new Date(report.compareFromDate),
                                    toDate: new Date(report.compareToDate)
                                }
                                : undefined
                        }
                        shouldCompare={shouldCompare}
                        compareData={
                            shouldCompare ? getOrdersDataFromRevenueData(
                                report.productTypeReport.comparePeriod.revenue, true
                            ) : undefined
                        }
                    />

                    <div className={styles.graphsGrid}>
                        <RevenueAnalytics
                            data={getFormattedRevenueData(report.productTypeReport.currentPeriod.revenue)}
                            dateRange={{
                                fromDate: new Date(report.fromDate),
                                toDate: new Date(report.toDate)
                            }}
                            compareDateRange={
                                shouldCompare
                                    ? {
                                        fromDate: new Date(report.compareFromDate),
                                        toDate: new Date(report.compareToDate)
                                    }
                                    : undefined
                            }
                            shouldCompare={shouldCompare}
                            compareData={
                                shouldCompare ? getFormattedRevenueData(
                                    report.productTypeReport.comparePeriod.revenue, true
                                ) : undefined
                            }
                        />

                        <QuantityAnalytics
                            data={getQuantityDataFromRevenueData(report.productTypeReport.currentPeriod.revenue)}
                            dateRange={{
                                fromDate: new Date(report.fromDate),
                                toDate: new Date(report.toDate)
                            }}
                            compareDateRange={
                                shouldCompare
                                    ? {
                                        fromDate: new Date(report.compareFromDate),
                                        toDate: new Date(report.compareToDate)
                                    }
                                    : undefined
                            }
                            shouldCompare={shouldCompare}
                            compareData={
                                shouldCompare ? getQuantityDataFromRevenueData(
                                    report.productTypeReport.comparePeriod.revenue, true
                                ) : undefined
                            }
                        />

                        <NetAnalytics
                            data={getNetData(report.productTypeReport.currentPeriod.revenue)}
                            dateRange={{
                                fromDate: new Date(report.fromDate),
                                toDate: new Date(report.toDate)
                            }}
                            compareDateRange={
                                shouldCompare
                                    ? {
                                        fromDate: new Date(report.compareFromDate),
                                        toDate: new Date(report.compareToDate)
                                    }
                                    : undefined
                            }
                            shouldCompare={shouldCompare}
                            compareData={
                                shouldCompare ? getNetData(
                                    report.productTypeReport.comparePeriod.revenue, true
                                ) : undefined
                            }
                        />

                        <RefundsAnalytics
                            data={getFormattedRefundsData(report.productTypeReport.currentPeriod.refunds)}
                            dateRange={{
                                fromDate: new Date(report.fromDate),
                                toDate: new Date(report.toDate)
                            }}
                            compareDateRange={
                                shouldCompare
                                    ? {
                                        fromDate: new Date(report.compareFromDate),
                                        toDate: new Date(report.compareToDate)
                                    }
                                    : undefined
                            }
                            shouldCompare={shouldCompare}
                            compareData={
                                shouldCompare ? getFormattedRefundsData(
                                    report.productTypeReport.comparePeriod.refunds, true
                                ) : undefined
                            }
                        />

                        <TopPromotionsCostsAnalytics 
                            data={getPromotionsCostsData(report.productTypeReport.currentPeriod.promotionCosts)}
                            dateRange={{
                                fromDate: new Date(report.fromDate),
                                toDate: new Date(report.toDate)
                            }}
                            compareDateRange={
                                shouldCompare
                                    ? {
                                        fromDate: new Date(report.compareFromDate),
                                        toDate: new Date(report.compareToDate)
                                    }
                                    : undefined
                            }
                            shouldCompare={shouldCompare}
                            compareData={
                                shouldCompare
                                    ? getPromotionsCostsData(report.productTypeReport.comparePeriod.promotionCosts)
                                    : undefined
                            }
                        />

                        <TopPaymentMethodsAnalytics
                            data={getPaymentMethodsData(report.productTypeReport.currentPeriod.revenue)}
                            dateRange={{
                                fromDate: new Date(report.fromDate),
                                toDate: new Date(report.toDate)
                            }}
                            compareDateRange={
                                shouldCompare
                                    ? {
                                        fromDate: new Date(report.compareFromDate),
                                        toDate: new Date(report.compareToDate)
                                    }
                                    : undefined
                            }
                            shouldCompare={shouldCompare}
                            compareData={
                                shouldCompare
                                    ? getPaymentMethodsData(report.productTypeReport.comparePeriod.revenue)
                                    : undefined
                            }
                        />
                    </div>

                    <div className={styles.graphsGrid__secondary}>
                        <TopProductsAnalytics
                            data={report.productTypeReport.currentPeriod.topProducts}
                            compareData={
                                shouldCompare
                                ? report.productTypeReport.comparePeriod.topProducts
                                : undefined
                            }
                            dateRange={{
                                fromDate: new Date(report.fromDate),
                                toDate: new Date(report.toDate)
                            }}
                            compareDateRange={
                                shouldCompare
                                    ? {
                                        fromDate: new Date(report.compareFromDate),
                                        toDate: new Date(report.compareToDate)
                                    }
                                    : undefined
                            }
                            shouldCompare={shouldCompare}
                        />
                    </div>
                </div>
            </PageContainer>
        </PageWrapper>
    )
};

export default AdvancedAnalyticsReportPage;