import {FC, ReactNode, useEffect, useState} from "react";
import styles from '../AdvancedAnalyticsPage.module.scss';
import Button, {ButtonStyles} from "../../../components/UI/Button/Button";
import {Checkbox, ConfigProvider, Divider, Select} from 'antd';
import Switch from "../../../components/UI/Switch/Switch";
import DateRangePicker from "../../../components/UI/DateRangePicker/DateRangePicker";
import {format} from "date-fns";
import {useAfterMountEffect} from "../../../hooks/useAfterMountEffect";
import {AntDesignSelectorConfig} from "../../../components/UI/AntDesignSelectorConfig/AntDesignSelectorConfig";
import {Product} from "../../../types/Product/Product";
import CustomMultiSelector from "../../../components/UI/CustomMultiSelector/CustomMultiSelector";

interface SelectorHeaderChooseAllProps {
    checked: boolean;
    setIsChecked: (value: boolean) => void;
    title: string;
    length?: number;
}

const dropdownHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#f1f1f1',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '5px',
    border: '1px solid #ddd',
    width: '200px',
};

const dropdownStyle = {
    position: 'absolute',
    top: '40px', // Adjust based on how much space you need from the top
    left: '0',
    width: '300px', // Adjust to match your layout
    backgroundColor: 'white',
    border: '1px solid #ddd',
    borderRadius: '5px',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    zIndex: '1000',
};

const SelectorHeaderChooseAll: FC<SelectorHeaderChooseAllProps> = ({ title, setIsChecked, checked, length }) => {
    return (
        <div className={styles.selectorHeaderAll}>
            <span style={{ marginLeft: '5px' }}>Selected: {
                length
            }</span>

            <div className={styles.selectorHeaderAllBtn} onClick={() => {setIsChecked(!checked)}}>
                <ConfigProvider theme={
                    {
                        token: {
                            colorPrimary: 'var(--primary_color)'
                        }
                    }
                }>
                    <Checkbox
                        checked={checked}
                        onClick={(event) => {
                        event.preventDefault();
                        setIsChecked(!checked);
                        }}
                    />
                </ConfigProvider>
                <span style={{ color: 'var(--dark_color)' }}>{title}</span>
            </div>
        </div>
    )
};

interface AdvancedAnalyticsPageHeaderProps {
    options: Record<string, any>;
    setOptions: (options: Record<string, any>) => void;
    availableProducts: string[];
    availableCountries: string[];
    openCreatePresetModal: () => void;
    onCreateReport: () => void;
    createReportLoading: boolean;
}

const customerPickerOptions = ['All', 'New', 'Existing'];
const dateRangePickerOptions = ['Today', 'This Week', 'This Month', 'This Year'];

const AdvancedAnalyticsPageHeader: FC<AdvancedAnalyticsPageHeaderProps> = ({
   options,
   setOptions,
   availableProducts,
   availableCountries,
   openCreatePresetModal,
   onCreateReport,
   createReportLoading
}) => {
    const [dateRangeFrom, setDateRangeFrom] = useState<Date | null>(new Date());
    const [dateRangeTo, setDateRangeTo] = useState<Date | null>(null);
    const [compareFrom, setCompareFrom] = useState<Date | null>(null);
    const [compareTo, setCompareTo] = useState<Date | null>(null);

    const [isSelectorOpen, setIsSelectorOpen] = useState(false);

    const toggleSelector = () => {
        setIsSelectorOpen(!isSelectorOpen);
    };

    const compareDateRangeFront: ReactNode = (
        <div className={styles.compareDateRangeFront}>
            {options.compareToPrevious && compareFrom && compareTo
                ? (
                    <span>
                {
                    format(compareFrom ?? new Date(), 'MMM dd, yyyy')
                    + ' - ' +
                    format(compareTo ?? new Date(), 'MMM dd, yyyy')
                }
                    </span>
             )
            : (
            <span>
                    Choose Date Range
                </span>)}
        </div>
    );

    const DateRangeFront: ReactNode = (
        <div className={styles.compareDateRangeFront}
             style={{borderLeft: '1px solid var(--border_color_secondary)', borderRadius: '0 8px 8px 0'}}>
            <span>
                {
                    format(dateRangeFrom ?? new Date(), 'MMM dd, yyyy')
                    + ' - ' +
                    format(dateRangeTo ?? new Date(), 'MMM dd, yyyy')
                }
            </span>
        </div>
    );

    const subtractDaysFromCurrentDate = (days: number): Date => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - days);
        return currentDate;
    };

    const setCompareDateRange = (): void => {
        if (dateRangeFrom && dateRangeTo) {
            const timeSpan = dateRangeTo.getTime() - dateRangeFrom.getTime();
            if (timeSpan) {
                const newCompareFromDate = new Date(dateRangeFrom.getTime() - timeSpan - 86400000);
                const newCompareToDate = new Date(dateRangeTo.getTime() - timeSpan - 86400000);
                setCompareFrom(newCompareFromDate);
                setCompareTo(newCompareToDate);
            }
        } else {
            const timeSpan = 1;
            const newCompareFromDate = dateRangeFrom ?? new Date();
            newCompareFromDate.setDate(newCompareFromDate.getDate() - timeSpan);
            setCompareFrom(newCompareFromDate);
        }
    };

    useEffect(() => {
        switch (options.dateRange) {
            case 'Today':
                setDateRangeFrom(new Date());
                setDateRangeTo(null);
                break;
            case 'This Week':
                setDateRangeFrom(subtractDaysFromCurrentDate(7));
                setDateRangeTo(new Date());
                break;
            case 'This Month':
                setDateRangeFrom(subtractDaysFromCurrentDate(30));
                setDateRangeTo(new Date());
                break;
            case 'This Year':
                setDateRangeFrom(subtractDaysFromCurrentDate(365));
                setDateRangeTo(new Date());
                break;
            default:
                break;
        }
    }, [options.dateRange]);

    useEffect(() => {
        options.compareToPrevious && setCompareDateRange();
    }, [options.compareToPrevious]);

    useEffect(() => {
        setOptions({
            ...options,
            dateRangeFrom: dateRangeFrom,
            dateRangeTo: dateRangeTo,
        });
    }, [dateRangeFrom, dateRangeTo]);

    useAfterMountEffect(() => {
        setOptions({...options, compareFromDate: compareFrom, compareToDate: compareTo})
    }, [compareFrom, compareTo])

    return (
        <div className={styles.analyticsPageHeader}>
            <div className={styles.title}>
                <h2>Advanced Product Analytics</h2>
                <h4>Select the desired values and compare, review the data</h4>
            </div>

            <div className={styles.selectors}>
                <div className={styles.selectorsItem}>
                    <span>Products</span>
                    <CustomMultiSelector
                        isOpen={isSelectorOpen}
                        setIsOpen={(value) => {setIsSelectorOpen(value)}}
                    />
                </div>

                <div className={styles.selectorsItem}>
                    <span>Country</span>
                    <AntDesignSelectorConfig>
                        <Select
                            mode={'multiple'}
                            style={{width: '100%'}}
                            showSearch
                            maxTagCount={2}
                            maxTagTextLength={5}
                            options={
                                availableCountries.map((country) => ({label: country, value: country }))
                            }
                            value={options.countries}
                            onChange={(value) => {
                                setOptions({...options, countries: value});
                            }}
                            dropdownRender={(menu) => (
                                <>
                                    <SelectorHeaderChooseAll
                                        checked={options.countries.length === availableCountries.length}
                                        setIsChecked={(value) => {
                                            value
                                                ? setOptions({...options, countries: availableCountries})
                                                : setOptions({...options, countries: []});
                                        }}
                                        title={'All Countries'}
                                        length={options.countries.length === availableCountries.length ? availableCountries.length : options.countries.length}
                                    />
                                    <Divider style={{ margin: '8px 0' }} />
                                    {menu}
                                </>
                            )}
                        />
                    </AntDesignSelectorConfig>
                </div>

                <div className={styles.selectorsItem}>
                    <span>Customer</span>
                    <div className={styles.customerPicker}>
                        {customerPickerOptions.map((option, index) => (
                            <div
                                key={index}
                                style={{
                                    backgroundColor: options.customer === option
                                        ? 'var(--light_gray)'
                                        : 'transparent'
                                }}
                                onClick={() => {
                                    setOptions({...options, customer: option })
                                }}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.selectorsItem}>
                    <span>Date Range</span>

                    <div className={styles.dateRange}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Select: {
                                        selectorBg: 'var(--light_color)'
                                    },
                                },
                                token: {
                                    colorBorder: 'var(--border_color_secondary)'
                                },
                            }}
                        >
                            <Select
                                style={{ width: '50%' }}
                                variant={'borderless'}
                                options={dateRangePickerOptions.map((option) => ({ label: option, value: option }))}
                                value={options.dateRange}
                                onChange={(value) => {
                                    setOptions({...options, dateRange: value});
                                }}
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '0' }} />
                                        <button
                                            className={styles.customDateRangeButton}
                                            onClick={() => {setOptions({...options, dateRange: 'Custom'})}}
                                        >Custom</button>
                                    </>
                                )}
                            />
                        </ConfigProvider>

                        <DateRangePicker
                            fromDate={dateRangeFrom}
                            setFromDate={setDateRangeFrom}
                            toDate={dateRangeTo}
                            setToDate={setDateRangeTo}
                            disabled={options.dateRange !== 'Custom'}
                            front={DateRangeFront}
                            maxWidth={'50%'}
                        />
                    </div>
                </div>

                <div className={styles.selectorsItem}>
                    <span>
                        Compare to previous period
                    </span>

                    <div className={styles.compare}>
                        <Switch checked={options.compareToPrevious} setChecked={(checked) => {setOptions({...options, compareToPrevious: checked})}} />
                        <DateRangePicker
                            fromDate={compareFrom}
                            setFromDate={setCompareFrom}
                            toDate={compareTo}
                            setToDate={setCompareTo}
                            disabled={!options.compareToPrevious}
                            front={compareDateRangeFront}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.buttons}>
                <Button isLoading={createReportLoading} height={32} style={ButtonStyles.PRIMARY} onClick={onCreateReport}>
                    Go to Report
                </Button>
                <Button height={32} style={ButtonStyles.SECONDARY} onClick={openCreatePresetModal}>
                    Create Preset
                </Button>
            </div>
        </div>
    )
};

export default AdvancedAnalyticsPageHeader;