import React, {FC} from "react";
import styles from './TableDropdown.module.scss';
import {SpriteIcon} from "../SpriteIcon/SpriteIcon";
import {ConfigProvider, Dropdown} from "antd";

interface TableDropdownProps {
    menuItems: any
}

const TableDropdown: FC<TableDropdownProps> = ({ menuItems }) => (
    <ConfigProvider
        theme={{
            token: {
                colorBgElevated: 'var(--light_color)',
                colorText: 'var(--dark_color)'
            },
        }}
    >
        <Dropdown menu={menuItems} trigger={['click']} placement={'bottomLeft'}>
            <div className={styles.customDropdownFace}>
                <SpriteIcon iconId={'ellipsis'} width={15} height={3} />
            </div>
        </Dropdown>
    </ConfigProvider>
);

export default TableDropdown;